import { ChangeEvent, FormEvent, useEffect, useRef } from 'react'
import { isLeadingZero } from '@/libs/utils'

type Props = {
  value: number
  onChange: (value: number) => void
  onClick?: () => void
  className?: string
}

const NumericInput: React.FC<Props> = ({
  value,
  onChange,
  onClick,
  className,
}) => {
  const inputReference = useRef<HTMLInputElement>(
    document.createElement('input'),
  )
  useEffect(() => {
    if (isLeadingZero(inputReference.current.value)) {
      // reset value to avoid leading-zero
      inputReference.current.value = '0'
    }
  }, [value])

  return (
    <input
      type="number"
      // for displaying numpad in mobile
      pattern="\d*"
      ref={inputReference}
      value={value}
      // blur to prevent number change on hover scrolling
      onWheel={(e) => e.currentTarget.blur()}
      onChange={(e) => onChange(parseInt(e.target.value, 10))}
      onClick={onClick}
      className={`appearance-none ${className}`}
    />
  )
}

export default NumericInput
