import {
  BLUESWEEP_BLOG_URL,
  BLUESWEEP_PRESS_KIT_URL,
  BLUESWEEP_TWITTER_URL,
} from '@/constants'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import { TC_URL } from '@/libs/termsAndConditions'

const FooterSection = () => {
  const linkClass = 'hover:text-primary active:text-primary'
  const { trackEvent } = useAnalytics()

  const handleLinkClick = (linkName: string) => {
    trackEvent(AnalyticsEvent.FooterLinkClicked, { link: linkName })
  }

  return (
    <div className="mt-12 pb-4 flex justify-between items-end text-sm text-slate-600">
      <div>© {new Date().getFullYear()} bluematterlabs</div>
      <div className="flex flex-col md:flex-row space-x-6 space-y-2 md:space-y-0 text-right">
        <a
          href={BLUESWEEP_BLOG_URL}
          target="_blank"
          rel="noreferrer"
          className={linkClass}
          onClick={() => handleLinkClick('blog')}
        >
          Blog
        </a>
        <a
          href={BLUESWEEP_TWITTER_URL}
          target="_blank"
          rel="noreferrer"
          className={linkClass}
          onClick={() => handleLinkClick('contact us')}
        >
          Contact Us
        </a>
        <a
          href={BLUESWEEP_PRESS_KIT_URL}
          target="_blank"
          rel="noreferrer"
          className={linkClass}
          onClick={() => handleLinkClick('press kit')}
        >
          Press Kit
        </a>
        <a
          href={TC_URL}
          target="_blank"
          rel="noreferrer"
          className={linkClass}
          onClick={() => handleLinkClick('terms')}
        >
          Terms of Service
        </a>
      </div>
    </div>
  )
}

export default FooterSection
