export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const isLeadingZero = (num: string): boolean => {
  const regx_check_leading_zero = /\b0+/g
  return regx_check_leading_zero.test(num)
}

export const isNumber = (value: number | string): boolean => {
  return typeof value === 'number'
}
