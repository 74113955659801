import { API_HOST } from "@/constants"
import { fetcher } from "@/libs/fetcher"
import { CollectionDetailApi } from "@/types"
import useSWR from "swr"

export const useCollectionDetail = (slug: string | string[] | undefined) => {
  let shouldFetch = true
  if (typeof slug !== 'string') {
    shouldFetch = false
  } 

  const url = `${API_HOST}/v2/collectionDetailBySlug/${slug}`
  const { data, error } = useSWR(shouldFetch ? url : null, fetcher)
  
  return {
    data: (data?.data || null) as CollectionDetailApi | null,
    error,
    isLoading: !error && !data,
  }
}
