export const CURRENT_VERSION = '2022-08-17'
export const TC_URL = 'https://github.com/bluematterlabs/terms/blob/main/TERM.md'

const TC_VERSION_KEY = 'bluesweepTCVersion'
const TC_SIGNER_KEY = 'bluesweepTCSigner'
const TC_SIGNATURE_KEY = 'bluesweepTCSignature'
const TC_SIGNATURE_TIMESTAMP_KEY = 'bluesweepTCSignatureTimestamp'

// TODO: keep on the server
// TODO: sign per wallet

export const getSignedTCVersion = (): string | null => {
  const version = localStorage.getItem(TC_VERSION_KEY)
  if (!version) {
    return null
  }

  return version
}

export const saveSignedTC = (signature: string, signerAddress: string) => {
  localStorage.setItem(TC_SIGNATURE_KEY, signature)
  localStorage.setItem(TC_SIGNER_KEY, signerAddress)
  localStorage.setItem(TC_VERSION_KEY, CURRENT_VERSION)
  localStorage.setItem(TC_SIGNATURE_TIMESTAMP_KEY, Date.now().toString())
}

export const getTCMessage = (walletAddress: string): string => { 
  return `Welcome to BlueSweep!

Please sign to let us verify that you are the owner of this address:

${walletAddress}
  
This will not cost you any gas fees.
  
By signing you accept the BlueSweep Terms of Service:
${TC_URL}
  
[${new Date().toISOString()}]` 
}