import { BLUESWEEP_OPERATOR_CONTRACT } from '@/constants'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import { formatRelativeTimeFromISO } from '@/libs/datetime'
import { getEtherscanUrl } from '@/libs/transaction'
import { SaleActivityParams, CollectionDetailApi, Marketplace } from '@/types'
import { useTransition, animated } from 'react-spring'
import { BiLinkExternal } from 'react-icons/bi'
import OptimizedImage from '@/components/OptimizedImage'
import { DateTime } from 'luxon'
import { useForceUpdate } from '@/hooks/useForceUpdate'
import Icon from '@/components/Icon'
import { getAssetSourceLink } from '@/libs/marketplace'
import { formatEtherWithDecimal } from '@/libs/bigNumberMath'

type Props = {
  collectionDetail: CollectionDetailApi | null
  latestSaleActivities: SaleActivityParams[] | null
  error: any
  isLoading: boolean
}
const LatestSaleActivitiesSection: React.FC<Props> = ({
  collectionDetail,
  latestSaleActivities,
  error,
  isLoading,
}) => {
  const { trackEvent } = useAnalytics()
  const transitions = useTransition(latestSaleActivities, {
    key: (saleActivity: SaleActivityParams) =>
      saleActivityUniqueKey(saleActivity),
    from: { backgroundColor: 'rgb(147, 197, 253, 1)' }, // bg-blue-500
    enter: { backgroundColor: 'rgb(147, 197, 253, 0)' },
    delay: 200,
    config: {
      duration: 400,
    },
  })

  if (error) {
    console.error('failed to load activities', error)
    return <></>
  }

  if (isLoading) {
    // TODO: loading animation
    return <></>
  }

  return (
    <div className="w-full">
      <div className="mt-4 min-w-[250px]">
        {transitions(({ backgroundColor }, saleActivity) => {
          if (!saleActivity) {
            return <></>
          }
          const isBlueSweepTx =
            saleActivity.buyer.address.toLowerCase() ===
            BLUESWEEP_OPERATOR_CONTRACT

          return (
            <animated.div
              key={saleActivityUniqueKey(saleActivity)}
              style={{ backgroundColor }}
            >
              <ActivityRow
                collectionDetail={collectionDetail}
                saleActivity={saleActivity}
                isBlueSweepTx={isBlueSweepTx}
                dateLink={getEtherscanUrl(
                  saleActivity.txHash,
                  saleActivity.chainId,
                )}
                onClickDate={() => {
                  trackEvent(
                    AnalyticsEvent.LatestSaleActivitiesLinkToExplorerClicked,
                  )
                }}
                onClickMarketplaceIcon={() => {
                  trackEvent(
                    AnalyticsEvent.LatestSaleActivitiesLinkToMarketplaceClicked,
                  )
                }}
              />
            </animated.div>
          )
        })}
      </div>
    </div>
  )
}

const ActivityRow: React.FC<{
  collectionDetail: CollectionDetailApi | null
  saleActivity: SaleActivityParams
  isBlueSweepTx: boolean
  dateLink: string
  onClickDate: () => void
  onClickMarketplaceIcon: () => void
}> = ({
  collectionDetail,
  saleActivity,
  isBlueSweepTx,
  dateLink,
  onClickDate,
  onClickMarketplaceIcon,
}) => {
  // to keep the component time update during seconds change
  // and stop the auto update if the relative time is more than 1 minute
  const isMatchedlessThan1Minute =
    !!saleActivity.matchedAt &&
    DateTime.fromISO(saleActivity.matchedAt) >
      DateTime.utc().minus({ minutes: 1 })
  useForceUpdate(isMatchedlessThan1Minute)

  const imageUrl = saleActivity.tokenImage || collectionDetail?.image || ''

  return (
    <div className="py-2 md:px-4">
      <div className="flex items-center space-x-2">
        {/* image */}
        <div className="relative w-16 h-16 md:w-12 md:h-12 shrink-0 overflow-hidden rounded">
          {imageUrl && (
            <OptimizedImage
              src={imageUrl}
              width={128}
              height={128}
              alt={saleActivity.tokenName}
              className="md:opacity-80"
            />
          )}
        </div>

        {/* detail */}
        <div className="w-full">
          {/* Token ID & Price */}
          <div className="flex justify-between md:text-sm text-slate-500 font-medium">
            <div>
              <div>#{saleActivity.tokenId}</div>
            </div>
            <div>
              {saleActivity?.paymentToken?.symbol === 'ETH' ? (
                <>Ξ {formatEtherWithDecimal(saleActivity.paymentAmount, 5)}</>
              ) : (
                <>
                  {formatEtherWithDecimal(saleActivity.paymentAmount, 5)}{' '}
                  {saleActivity?.paymentToken?.symbol}
                </>
              )}
            </div>
          </div>

          {/* Time */}
          <div className="flex items-baseline justify-between">
            <div className="flex items-center">
              <div className="bg-slate-300 text-white px-1 rounded text-sm md:text-xs font-semibold mr-1">
                SOLD
              </div>
              {collectionDetail?.address && (
                <a
                  href={getAssetSourceLink(
                    saleActivity.marketplace,
                    saleActivity.chainId,
                    collectionDetail.address,
                    saleActivity.tokenId,
                  )}
                  target="_blank"
                  rel="noreferrer"
                  onClick={onClickMarketplaceIcon}
                >
                  {saleActivity.marketplace === Marketplace.quix ? (
                    <Icon name="quix" size={14} className="opacity-50" />
                  ) : (
                    <Icon name="opensea" size={14} className="opacity-50" />
                  )}
                </a>
              )}
            </div>
            <a
              href={dateLink}
              className={`md:text-sm flex space-x-1 items-center ${
                isBlueSweepTx
                  ? 'text-blue-500 hover:text-blue-400'
                  : 'text-slate-400 hover:text-slate-300'
              }`}
              target="_blank"
              rel="noreferrer"
              onClick={onClickDate}
            >
              <span>{formatRelativeTimeFromISO(saleActivity.matchedAt)}</span>
              <BiLinkExternal size={12} className={`mt-1`} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

// Correct unique key is really important to prevent
// unwanted animation and list shuffling which can be annoying for users.
function saleActivityUniqueKey(saleActivity: SaleActivityParams): string {
  if (!saleActivity || !saleActivity.txHash || !saleActivity.tokenId) {
    return ''
  }

  // should be okay as there should not be multiple of same tokenIds in the same txHash
  return `${saleActivity.txHash}-${saleActivity.tokenId}`
}

export default LatestSaleActivitiesSection
