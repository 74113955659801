import { AiOutlineCloseCircle } from 'react-icons/ai'
import Button from '@/components/Button'
import { bnSum } from '@/libs/bigNumberMath'
import { useEffect } from 'react'
import { ItemSellOrderParams } from '@/types'
import { formatEther } from 'ethers/lib/utils'
import ERC721Image from '@/components/ERC721Image'
import useBounce from '@/hooks/useBounce'
import { animated } from 'react-spring'
import usePreviousValue from '@/hooks/usePreviousValue'

export type CheckOutSectionProps = {
  items: ItemSellOrderParams[]
  isCheckingOut: boolean
  isTxConfirmed?: boolean
  onClickCheckOut: () => void
  onRemoveItem: (id: string) => void
  onClearCart: () => void
  onClickReviewOrder: () => void
  percentageGasSaved?: number
}
const CheckOutSection: React.FC<CheckOutSectionProps> = ({
  items,
  isCheckingOut,
  isTxConfirmed,
  onClickCheckOut,
  onRemoveItem,
  onClearCart,
  onClickReviewOrder,
  percentageGasSaved,
}) => {
  const totalPaymentEth = bnSum(items.map((item) => item.txBuyData.buy.value))

  const prevItems = usePreviousValue(items)

  const { trigger, styles } = useBounce({
    scale: 1.05,
    config: { tension: 240, friction: 24 },
  })

  useEffect(() => {
    // bounce bounce button only when items are added
    if (!prevItems || items.length > prevItems.length) {
      trigger()
    }
  }, [items])

  const handleRemoveItem = (tokenId: string) => {
    if (isCheckingOut) {
      return
    }
    onRemoveItem(tokenId)
  }

  return (
    <div className="z-50 pt-1 md:pt-0 fixed bottom-0 inset-x-0 pb-4 flex justify-between items-center text-sm text-slate-700 backdrop-blur-md bg-slate-100/70 drop-shadow-2xl">
      {/* Backdrop */}
      <div className="absolute w-full h-0.5 top-0 inset-x-0 bg-gradient-to-r from-slate-200 to-slate-500 text-white text-xs leading-4" />

      {/* Left Panel */}
      <div>
        {/* Cart Header */}
        <div className="px-4 mt-4">
          <h2 className="inline-block font-bold text-md">My Cart</h2>

          <div className="inline-block">
            <div
              className="flex justify-center items-center ml-1
              bg-blue-200/50 rounded-full w-5 h-5
              text-xs font-medium text-slate-600"
            >
              {items.length}
            </div>
          </div>

          <div
            onClick={onClearCart}
            className="inline-block ml-2 text-xs font-medium text-slate-500 hover:text-rose-500 cursor-pointer"
          >
            Clear all
          </div>
          <div className="block md:hidden text-lg font-semibold text-slate-700 text-left mt-1">
            {formatEther(totalPaymentEth)} ETH
          </div>
        </div>

        {/* All items in the Cart */}
        <div className="hidden md:block">
          <div className="flex flex-wrap pr-4 max-h-36 overflow-y-auto no-scrollbar">
            {items.map((item) => (
              <div key={item.tokenId} className="pt-4 pl-4">
                <div className="h-20 w-20 relative">
                  {/* Use the same big image as the collection display, so we don't need to load image twice */}
                  <ERC721Image
                    src={item.image}
                    alt={item.name}
                    className="rounded"
                  />
                  <div
                    onClick={() => handleRemoveItem(item.tokenId)}
                    className="absolute -right-2 -top-2 text-slate-500 hover:text-rose-500 bg-white/90 cursor-pointer rounded-full"
                  >
                    <AiOutlineCloseCircle size="24" />
                  </div>
                  <div className="absolute w-full px-2 py-2 rounded-b-md bottom-0 inset-x-0 bg-gradient-to-t from-slate-700 text-white text-xs leading-4">
                    <div className="font-semibold">#{item.tokenId}</div>
                    <div className="text-xs">
                      Ξ {formatEther(item.paymentAmount)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Summary & Sweep button */}
      <div className="flex flex-col items-center mx-4 mt-1 xl:mx-8 shrink-0">
        <div className="flex items-baseline my-1">
          <div className="hidden md:block text-lg font-medium text-slate-700">
            Total: {formatEther(totalPaymentEth)} ETH
          </div>
        </div>
        <animated.div style={styles}>
          <CheckOutButton
            loading={isCheckingOut && !isTxConfirmed}
            onClick={onClickReviewOrder}
            isTxConfirmed={isTxConfirmed}
            itemsCount={items.length}
            className="flex"
          />
        </animated.div>
        {percentageGasSaved && (
          <div className="mt-1 hidden md:block">
            {percentageGasSaved < 60
              ? '✨'
              : percentageGasSaved < 70
              ? '🔥'
              : '🚀'}{' '}
            Saving{' '}
            <span className="font-semibold text-md text-transparent bg-clip-text bg-gradient-to-br from-blue-500 to-purple-600">
              {' '}
              {percentageGasSaved}%{' '}
            </span>
            gas with us
          </div>
        )}
      </div>
    </div>
  )
}

const CheckOutButton: React.FC<{
  onClick: () => void
  loading: boolean
  isTxConfirmed?: boolean
  itemsCount: number
  className?: string
}> = ({ onClick, isTxConfirmed, itemsCount, loading, className }) => {
  return (
    <Button loading={loading} onClick={onClick} className={className}>
      {isTxConfirmed ? (
        <>Sweep Done</>
      ) : (
        <>
          {itemsCount > 1 ? 'Sweep' : 'Buy'} {itemsCount} item
          {itemsCount > 1 && 's'}
        </>
      )}
    </Button>
  )
}

export default CheckOutSection
