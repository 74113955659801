import { calculateL1GasUsageForCallData, computeTxData } from "@/libs/bluesweep-sdk"
import { ItemSellOrderParams } from "@/types"
import { useEffect, useState } from "react"

export type SweepGasInfo = {
  normalGasUsed: number
  bluesweepGasUsed: number
  percentageSaved: number
} 

export const useSweepGasInfo = (selectedItems: ItemSellOrderParams[]) => {
  const [sweepGasInfo, setSweepGasInfo] = useState<SweepGasInfo | null>(null)

  // calculate gas used for saving %
  useEffect(() => {
    if (!selectedItems || !selectedItems.length) {
      return
    }

    const selectedItemTxDatas = selectedItems.filter(item => item).map((item) => item.txBuyData)

    const txParams = computeTxData(selectedItemTxDatas)
    if (txParams && txParams.data) {
      // gas for normal buy from qxt
      // 1 item  = 12,524
      // 5 items = 52,064
      const simulatedOverheadGas = 2639
      const simulatedNetOneExecutionGas = 9885
      const simulatedGasUsed =
        simulatedOverheadGas + simulatedNetOneExecutionGas * selectedItemTxDatas.length
      const bluesweepGasUsed = calculateL1GasUsageForCallData(
        txParams.data.toString(),
      )

      setSweepGasInfo({
        normalGasUsed: simulatedGasUsed,
        bluesweepGasUsed: simulatedOverheadGas,
        percentageSaved: Math.floor(
          (100 * (simulatedGasUsed - bluesweepGasUsed)) / simulatedGasUsed,
        ),
      })
    }
  }, [selectedItems])

  return { sweepGasInfo }
}