import { ImageLoaderProps } from "next/image"

// ref: https://developers.cloudflare.com/images/image-resizing/integration-with-frameworks/
export const cloudflareImageLoader = ({
  src,
  width,
  quality = 75,
}: ImageLoaderProps) => {

  const params = [`format=auto`,`width=${width}`, `quality=${quality}`]
  const paramsString = params.join(',')
  // return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
  return `https://www.bluesweep.xyz/cdn-cgi/image/${paramsString}/${normalizeSrc(
    src,
  )}`
}

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src
}