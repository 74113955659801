import React from 'react'
import { BsTwitter } from 'react-icons/bs'
import { MdInfoOutline, MdVerified } from 'react-icons/md'
import { SiDiscord } from 'react-icons/si'
import { TbExternalLink, TbWorld } from 'react-icons/tb'
import Image from 'next/image'

type IconProps = {
  name: string
  size?: number
  className?: string
  alt?: string
}

const Icon: React.FC<IconProps> = ({ name, size, className, alt }) => {
  const props = { size, className }

  // icon ref: https://react-icons.github.io/react-icons/search
  const icons: Record<string, JSX.Element> = {
    verify: <MdVerified {...props} />,
    twitter: <BsTwitter {...props} />,
    discord: <SiDiscord {...props} />,
    website: <TbWorld {...props} />,
    externalLink: <TbExternalLink {...props} />,
    info: <MdInfoOutline {...props} />,
    quix: (
      <ImageIcon src="/icon-quixotic.png" alt={alt ? 'Quix' : ''} {...props} />
    ),
    opensea: (
      <ImageIcon
        src="/icon-opensea.png"
        alt={alt ? 'OpenSea' : ''}
        {...props}
      />
    ),
  }

  if (!icons[name]) {
    throw new Error(`Icon ${name} not found`)
  }

  return <div>{icons[name]}</div>
}

function ImageIcon({
  src,
  alt,
  size = 16,
  className,
}: {
  src: string
  alt: string
  size?: number
  className?: string
}) {
  return (
    <div className="flex">
      <Image
        src={src}
        layout="fixed"
        alt={alt}
        width={size}
        height={size}
        className={className}
      />
    </div>
  )
}

export default Icon
