type Props = {
  value: string
  placeholder: string
  onChange: (value: string) => void
  onEnter?: () => void
  onClick?: () => void
  className?: string
}

const Searchinput: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
  onClick,
  className,
}) => {
  return (
    <input
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={(event) => onChange(event.target.value)}
      onClick={onClick}
      className={`${className}`}
    />
  )
}

export default Searchinput
