import { DateTime } from 'luxon'

export const formatRelativeTime = (timestampInSeconds: number): string => {
  if (!timestampInSeconds || typeof timestampInSeconds !== 'number') {
    return ''
  }

  return DateTime.fromSeconds(timestampInSeconds).toRelative() || ''
}

export const formatRelativeTimeFromISO = (iso8601: string): string => {
  return DateTime.fromISO(iso8601).toRelative() || ''
}

export const isBeforeNow = (iso: string): boolean => {
  return DateTime.fromISO(iso) < DateTime.now() 
}

export const diffHours = (d1: Date, d2: Date) => {
  const diff = d1.getTime() - d2.getTime()
  return Math.ceil(diff / (1000 * 60 * 60))
}

export const formatLocaleTime = (iso: string): string => {
  if (!iso) {
    return ''
  }
  return DateTime.fromISO(iso).toLocaleString(DateTime.DATETIME_MED)
}