import CollectionExternalLinks from '@/components/CollectionExternalLinks'
import Icon from '@/components/Icon'
import { CollectionDetailApi } from '@/types'
import CollectionInfoSection from './CollectionInfoSection'

type Props = {
  collectionDetail: CollectionDetailApi | null
}
const CollectionHeaderSection: React.FC<Props> = ({ collectionDetail }) => {
  return (
    <div className="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-1 items-start">
      <div>
        {/* Collection Name */}
        <h2 className="flex items-center">
          <div className="text-3xl font-bold truncate">
            {collectionDetail?.name}
          </div>

          {collectionDetail?.verified && (
            <div className="ml-2 mt-1 text-2xl text-blue-500">
              <Icon name="verify" />
            </div>
          )}
        </h2>

        {/* Collection Links */}
        <div className="mt-2 ml-1">
          <CollectionExternalLinks
            website={collectionDetail?.website}
            twitter={collectionDetail?.twitter}
            discord={collectionDetail?.discord}
            etherscan={collectionDetail?.etherscan}
            apetimismPad={collectionDetail?.apetimismPad}
          />
        </div>
      </div>

      {/* Collection Stats */}
      <div className="md:ml-4 lg:ml-8 xl:ml-8 mt-1 w-full">
        <CollectionInfoSection collectionDetail={collectionDetail} />
      </div>
    </div>
  )
}

export default CollectionHeaderSection
