import { API_HOST } from '@/constants'
import { fetcher } from '@/libs/fetcher'
import { CollectionDetailApi } from '@/types'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import OptimizedImage from './OptimizedImage'
import { useRouter } from 'next/router'
import { RECENT_VIEW } from '@/pages/collections/[collectionName]'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'

type Props = {
  query: string
  close: () => void
}

const searchPanelClasses =
  'w-full absolute bg-slate-100 rounded-md mt-10 shadow-lg z-20 p-4'

const SearchResult: React.FC<Props> = ({ query, close }) => {
  const router = useRouter()
  const url = `${API_HOST}/v2/search/${query !== '' ? query : '-'}`
  const [history, setHistory] = useState<CollectionDetailApi[]>([])
  const { data, error } = useSWR(url, fetcher)
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    let recentItems: CollectionDetailApi[] =
      localStorage.getItem(RECENT_VIEW) != null
        ? JSON.parse(localStorage.getItem(RECENT_VIEW) as string)
        : []
    if (query !== '') {
      recentItems = recentItems.filter((item) => {
        if (item.name.includes(query) || item.slug.includes(query)) {
          return item
        }
      })
    }
    setHistory(recentItems)
  }, [query])

  const handleSearchHistory = (item: CollectionDetailApi) => {
    router.push(`/collections/${item.slug}`)
    close()
  }

  if (error) return <div className={searchPanelClasses}>Fail to search!</div>
  if (!data) return <div className={searchPanelClasses}>Loading...</div>

  let result = (data?.data || []) as CollectionDetailApi[]
  if (result.length === 0 && query.length !== 0) {
    return <div className={searchPanelClasses}>Not Found</div>
  }
  return (
    <div
      className={`${
        history?.length !== 0 || result.length !== 0
          ? 'bg-slate-100 shadow-lg'
          : ''
      } w-full absolute rounded-md mt-10 z-20`}
    >
      {history?.length !== 0 && (
        <div className="flex flex-col my-1 p-2">
          <span className="text-sm font-semibold mx-2 my-1">RECENT</span>
          {history.map((recentItem) => {
            return (
              <SearchResultRow
                key={recentItem.slug}
                item={recentItem}
                onClick={(item) => {
                  trackEvent(AnalyticsEvent.SearchCollectionClicked, {
                    collection: item.slug,
                  })
                  handleSearchHistory(item)
                }}
              />
            )
          })}
        </div>
      )}
      <div className="flex flex-col my-1 p-2 z-20 max-h-[500px] overflow-y-auto">
        {result.length !== 0 && (
          <span className="text-sm font-semibold mx-2 my-1">RESULT</span>
        )}
        {result.map((item) => (
          <SearchResultRow
            key={item.slug}
            item={item}
            onClick={(item) => {
              trackEvent(AnalyticsEvent.SearchCollectionClicked, {
                collection: item.slug,
              })
              handleSearchHistory(item)
            }}
          />
        ))}
      </div>
      <div
        onClick={close}
        className="fixed top-0 left-0 w-full bg-transparent z-[-1] min-h-screen"
      ></div>
    </div>
  )
}

const SearchResultRow: React.FC<{
  item: CollectionDetailApi
  onClick: (item: CollectionDetailApi) => void
}> = ({ item, onClick }) => {
  return (
    <div
      onClick={() => onClick(item)}
      className="my-1 p-2 hover:bg-white cursor-pointer flex flex-row justify-between items-center"
    >
      <div className="flex items-center space-x-4">
        <OptimizedImage
          className="rounded-md"
          src={item.image || ''}
          width="60"
          height="60"
          alt={item.name}
        />
        <div>
          <div className="text-slate-800 font-semibold">{item.name}</div>
          <div className="text-sm text-slate-600">
            {item.supplyTotal?.toLocaleString('en-US')} items
          </div>
        </div>
      </div>
      <div className="text-sm text-right">
        <div className="text-slate-500">Total Volume</div>
        <div className="text-slate-600 font-semibold">
          {item.volTotal.toFixed(2)} ETH
        </div>
      </div>
    </div>
  )
}

export default SearchResult
