import Spinner from '@/components/Spinner'
import { Dialog } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import {
  CollectionDetailApi,
  ItemSellOrderParams,
  Marketplace,
  TokenId,
} from '@/types'
import { bnSum } from '@/libs/bigNumberMath'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import EthPriceInUsd from '@/components/EthPriceInUsd'
import { parseLogs } from '@/libs/bluesweep-sdk'
import { eventOperatorBuy, SweepResult } from '@/libs/bluesweep-sdk/types'
import { BsCheckCircleFill, BsChevronDoubleDown } from 'react-icons/bs'
import { MdError } from 'react-icons/md'
import Button from '@/components/Button'
import { getEtherscanUrl } from '@/libs/transaction'
import OptimizedImage from '@/components/OptimizedImage'
import ERC721Image from '@/components/ERC721Image'
import TooltipContainer from '@/components/TooltipContainer'
import { BiLinkExternal } from 'react-icons/bi'
import { useIsOverflow } from '@/hooks/useIsOverflow'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { formatEther } from 'ethers/lib/utils'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import Icon from '@/components/Icon'
import { genTwitterLinkToShare } from '@/libs/twitter'
import { SweepGasInfo } from '@/hooks/useSweepGasInfo'
import { QxOpRewardData } from '@/hooks/useQxOpRewards'
import Image from 'next/image'

export type CheckOutResult =
  | {
      isReady: true
      isSuccessful: true
      isTotalFilled: boolean
      totalPaymentEth: BigNumber
      totalFilledPayment: BigNumber
      totalRefundedPayment: BigNumber
      totalRequestItems: number
      totalFilledItems: number
      totalRefundedItems: number
      filledItemMap: Record<string, eventOperatorBuy>
      sweepResult: SweepResult
    }
  | { isReady: true; isSuccessful: false }
  | { isReady: false }

type Props = {
  isOpen: boolean
  onCloseAfterDone: (
    txReceipt: ethers.providers.TransactionReceipt | undefined | null,
    checkOutResult: CheckOutResult,
  ) => void
  collectionInfo: CollectionDetailApi | null
  items: ItemSellOrderParams[]
  soldItems: Record<TokenId, boolean>
  sweepGasInfo: SweepGasInfo | null
  txHash: string
  isTxSent?: boolean
  isTxConfirmed?: boolean

  txReceipt?: ethers.providers.TransactionReceipt
  onClickDone?: (
    txReceipt: ethers.providers.TransactionReceipt | undefined | null,
  ) => void

  // for reviewing flow
  isReviewingOrder: boolean
  isValidatingOrder: boolean
  onCheckOutConfirmed: () => void
  onCheckOutCanceled: () => void
  onRemoveItem: (tokenId: string) => void
  onClearCart: () => void
}

// TODO: handle the tx revert case
const CheckOutConfirmationSection: React.FC<Props> = ({
  isOpen,
  onCloseAfterDone,
  collectionInfo,
  items,
  soldItems,
  sweepGasInfo,
  txHash,
  isTxSent,
  isTxConfirmed,
  txReceipt,

  isReviewingOrder,
  isValidatingOrder,
  onCheckOutConfirmed,
  onCheckOutCanceled,
  onRemoveItem,
  onClearCart,
}) => {
  const { trackEvent } = useAnalytics()
  const [checkOutResult, setCheckOutResult] = useState<CheckOutResult>({
    isReady: false,
  })

  const receivingItemsRef = useRef<HTMLDivElement>(null)
  const isItemsOverFlow = useIsOverflow(receivingItemsRef, items)
  const [validItems, setValidItems] = useState<ItemSellOrderParams[]>([])
  const [totalValidPaymentEth, setTotalValidPaymentEth] = useState<BigNumber>(
    BigNumber.from(0),
  )

  // TODO: refactor to move all of this out as this
  //  logic should not be here since this component
  //  should be just the display, not decoding part
  useEffect(() => {
    const total = bnSum(items.map((item) => item.txBuyData.buy.value))

    if (txReceipt) {
      // if failed
      if (txReceipt.status === 0) {
        setCheckOutResult({ isReady: true, isSuccessful: false })
        return
      }

      const result = parseLogs(txReceipt.logs)

      if (result && result.fulfillRequest && result.fulfilledAmount) {
        const { fulfillRequest, fulfilledAmount } = result
        const totalRequestItems = items.length

        // map tokenId to event
        const tokenFulFilledEventMap: Record<string, eventOperatorBuy> =
          result.operatorLogs.reduce((acc, log) => {
            return { ...acc, [log.tokenId.toString()]: log }
          }, {})

        let isTotalFilled: boolean
        let totalFilledPayment: BigNumber
        let totalRefundedPayment: BigNumber
        let totalFilledItems: number
        let totalRefundedItems: number

        // total filled
        if (fulfillRequest.eq(fulfilledAmount)) {
          isTotalFilled = true
          totalFilledPayment = total
          totalRefundedPayment = BigNumber.from(0)
          totalFilledItems = totalRequestItems
          totalRefundedItems = 0
        }
        // partial filled
        else {
          isTotalFilled = false
          totalFilledPayment = bnSum(
            result.operatorLogs.map((log) => log.paymentAmount),
          )
          totalRefundedPayment = total.sub(totalFilledPayment)
          totalFilledItems = result.operatorLogs.length
          totalRefundedItems = totalRequestItems - totalFilledItems
        }

        // TODO: may better group into the class object for ease of using
        const checkOutResult: CheckOutResult = {
          isReady: true,
          isSuccessful: true,
          isTotalFilled,
          totalPaymentEth: total,
          totalFilledPayment,
          totalRefundedPayment,
          totalRequestItems,
          totalFilledItems,
          totalRefundedItems,
          filledItemMap: tokenFulFilledEventMap,
          sweepResult: result,
        }

        setCheckOutResult(checkOutResult)
      }
    }
  }, [txReceipt, items])

  useEffect(() => {
    const validItems = items.filter((item) => !soldItems[item.tokenId])
    setValidItems(validItems)
    setTotalValidPaymentEth(
      bnSum(validItems.map((item) => item.txBuyData.buy.value)),
    )
  }, [items, soldItems])

  let subtitleMessage = ''

  if (isReviewingOrder) {
    subtitleMessage = 'Please review your order'
  } else if (!isTxSent) {
    subtitleMessage = 'Please confirm the transaction in your wallet'
  } else if (!isTxConfirmed) {
    subtitleMessage = 'Processing the sweep transaction'
  } else if (txReceipt?.status === 0) {
    subtitleMessage = 'Transaction failed'
  } else if (checkOutResult.isReady && checkOutResult.isSuccessful) {
    if (checkOutResult.isTotalFilled) {
      subtitleMessage = `Successfully swept all ${
        checkOutResult.totalFilledItems
      } item${checkOutResult.totalFilledItems > 1 ? 's' : ''}`
    } else {
      subtitleMessage =
        `Successfully swept ${checkOutResult.totalFilledItems} item${
          checkOutResult.totalFilledItems > 1 ? 's' : ''
        }.` +
        ` The payment for ${checkOutResult.totalRefundedItems} failed item${
          checkOutResult.totalRefundedItems > 1 ? 's' : ''
        } was refunded.`
    }
  }

  const handleClose = () => {
    // cannot close if not done
    if (!checkOutResult.isReady) {
      return
    }

    setCheckOutResult({ isReady: false })
    onCloseAfterDone(txReceipt, checkOutResult)
  }

  return (
    <Dialog
      open={isOpen}
      // can close only if the transaction is confirmed
      onClose={handleClose}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-slate-600/50" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-2 md:p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full flex flex-col max-w-lg xl:max-w-xl rounded-lg bg-white p-6 md:p-8 max-h-full 2xl:max-h-[80%]">
          <Dialog.Title>
            <div className="text-2xl font-semibold truncate">
              {checkOutResult.isReady && checkOutResult.isSuccessful
                ? `You swept ${checkOutResult.totalFilledItems} ${collectionInfo?.name} ✨`
                : `Sweeping ${validItems.length} ${collectionInfo?.name}`}
            </div>
          </Dialog.Title>
          <div className="text-slate-600 mt-2">
            {!isTxConfirmed && !isReviewingOrder && (
              <Spinner className="inline-block mr-1" />
            )}
            <span>{subtitleMessage}</span>
          </div>

          {/* Paying */}
          <div className="mt-6">
            <div className="text-lg font-medium">
              {checkOutResult.isReady ? 'Paid' : 'Paying'}
            </div>

            <div className="flex my-2 items-center justify-between">
              {/* left side info */}
              <div className="flex items-center">
                <div className="relative overflow-hidden p-6">
                  <OptimizedImage
                    src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"
                    layout="fill"
                    objectFit="cover"
                    className="absolute object-cover w-full h-full"
                    alt="ethereum"
                  />
                </div>
                <div className="ml-2 h-10">
                  <div className="text-slate-700 font-semibold">
                    {checkOutResult.isReady && checkOutResult.isSuccessful
                      ? formatEth(checkOutResult.totalFilledPayment)
                      : formatEth(totalValidPaymentEth)}{' '}
                    ETH
                  </div>
                  {checkOutResult.isReady &&
                    checkOutResult.isSuccessful &&
                    !checkOutResult.isTotalFilled && (
                      <div className="text-slate-500 text-sm">
                        {formatEth(checkOutResult.totalRefundedPayment)} ETH was
                        refunded
                      </div>
                    )}
                </div>
              </div>

              {/* rightside info */}
              <div className="text-right">
                <div className="text-slate-700 font-semibold">
                  {checkOutResult.isReady && checkOutResult.isSuccessful
                    ? formatEth(checkOutResult.totalFilledPayment)
                    : formatEth(totalValidPaymentEth)}{' '}
                  ETH
                </div>
                <div className="text-slate-500 text-sm">
                  $
                  <EthPriceInUsd
                    wei={
                      checkOutResult.isReady && checkOutResult.isSuccessful
                        ? checkOutResult.totalFilledPayment
                        : totalValidPaymentEth
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Receiving */}
          <div className="mt-2 md:mt-4 pb-2 md:pb-4">
            <div className="text-lg font-medium">
              {checkOutResult.isReady && checkOutResult.isSuccessful
                ? 'Received'
                : 'Receiving'}
            </div>

            {isItemsOverFlow && (
              <div className="text-sm text-slate-500">
                Scroll down to see all {items.length} items{' '}
                <BsChevronDoubleDown className="inline-block" size={12} />
              </div>
            )}
          </div>

          {/* Add flex-1 to fill the remaining height */}
          <div
            className="flex-1 overflow-y-scroll space-y-2 py-2 no-scrollbar"
            ref={receivingItemsRef}
          >
            {items.map((item, index) => {
              return (
                <TokenRow
                  item={item}
                  key={item.tokenId}
                  isTxSent={isTxSent}
                  isTxConfirmed={isTxConfirmed}
                  isReviewingOrder={isReviewingOrder}
                  isUnavailable={soldItems[item.tokenId]}
                  fulfilledLog={
                    checkOutResult.isReady && checkOutResult.isSuccessful
                      ? checkOutResult?.filledItemMap[item.tokenId]
                      : null
                  }
                  onRemoveItem={onRemoveItem}
                />
              )
            })}
          </div>

          <div>
            {!checkOutResult.isReady &&
              validItems.length > 0 &&
              items.length > validItems.length && (
                <div className="text-sm text-red-500 text-center pt-1">
                  {items.length - validItems.length} unavailable item
                  {items.length - validItems.length > 1 ? 's' : ''} will be
                  skipped
                </div>
              )}
            {!checkOutResult.isReady && !validItems.length && (
              <div className="text-sm text-red-500 text-center pt-1">
                No items are available for sweep 🥹
              </div>
            )}
          </div>

          <div className="mt-2 lg:mt-4">
            {collectionInfo && checkOutResult.isReady && txHash && (
              <div className="flex space-x-2">
                {/* TODO: etherscan button show alone is weird */}
                {/* Etherscan button */}
                <a
                  href={getEtherscanUrl(txHash, 10)}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    trackEvent(AnalyticsEvent.CheckOutConfirmationViewTxClicked)
                  }
                  className="w-full"
                >
                  <Button
                    type="line"
                    size="sm"
                    className="w-full hover:opacity-80 text-slate-600 font-semibold"
                  >
                    <Icon name="externalLink" size={16} className="mr-2" />
                    <span className="inline-block md:hidden">Etherscan</span>
                    <span className="hidden md:inline-block">
                      View on Etherscan
                    </span>
                  </Button>
                </a>

                {/* Twitter Share button (display only if successful) */}
                {checkOutResult.isSuccessful && (
                  <a
                    href={genTwitterLinkToShare({
                      collectionName: collectionInfo.name,
                      collectionTwitter: collectionInfo.twitter,
                      filledItems: checkOutResult.totalFilledItems,
                      totalPayment: checkOutResult.totalFilledPayment,
                      txHash,
                      chain: collectionInfo.chain,
                      percentageGasSaved: sweepGasInfo?.percentageSaved,
                    })}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      trackEvent(
                        AnalyticsEvent.CheckOutConfirmationShareClicked,
                      )
                    }}
                    className="w-1/2 flex-shrink-0"
                  >
                    <Button
                      type="line"
                      size="sm"
                      className="w-full hover:opacity-80 text-slate-600 font-semibold"
                    >
                      <Icon
                        name="twitter"
                        className="mr-2 text-[#1d9bf0]"
                        size={16}
                      />
                      <span className="inline-block md:hidden">Share</span>
                      <span className="hidden md:inline-block">
                        Share on Twitter
                      </span>
                    </Button>
                  </a>
                )}
              </div>
            )}

            {isReviewingOrder && (
              <div className="flex space-x-4">
                <Button type="line" onClick={onCheckOutCanceled}>
                  Back
                </Button>

                {/* No valid items */}
                {!validItems.length && (
                  <Button
                    onClick={() => onClearCart()}
                    className="flex-1"
                    type="dangerLine"
                  >
                    Clear the Cart
                  </Button>
                )}

                {/* There are some valid items */}
                {validItems.length > 0 && (
                  <Button
                    onClick={onCheckOutConfirmed}
                    className="flex-1"
                    loading={isValidatingOrder}
                  >
                    {isValidatingOrder
                      ? 'Verifying orders'
                      : `Confirm ${validItems.length > 1 ? 'Sweep' : 'Buy'}`}
                  </Button>
                )}
              </div>
            )}

            {checkOutResult.isReady && (
              <Button
                type="base"
                size="sm"
                className="mt-2 w-full font-semibold"
                onClick={handleClose}
              >
                Done
              </Button>
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

const getItemStatus = ({
  isTxSent,
  isTxConfirmed,
  isSuccessful,
  isUnavailable,
}: {
  isTxSent: boolean | undefined
  isTxConfirmed: boolean | undefined
  isSuccessful: boolean | undefined | null
  isUnavailable: boolean | undefined | null
}) => {
  if (!isTxSent && isUnavailable) {
    // sold, expired, seller transfered out to other wallet
    // or invalid sell data
    return 'Unavailable'
  }

  if (!isTxSent) {
    return 'Pending'
  } else if (!isTxConfirmed) {
    return 'Processing'
  } else if (isSuccessful) {
    return 'Fulfilled'
  } else {
    return 'Refunded'
  }
}

const QxRewardRow: React.FC<{
  items: ItemSellOrderParams[]
  opRewardData: QxOpRewardData
  isTxSent: boolean | undefined
  isTxConfirmed: boolean | undefined
  checkOutResult: CheckOutResult
}> = ({ items, opRewardData, isTxSent, isTxConfirmed, checkOutResult }) => {
  const { totalRewardPerMilleNum, opRewardNum, opPriceNum } =
    opRewardData.result
  const [opRewardNumDisplay, setOpRewardNumDisplay] = useState(opRewardNum)

  useEffect(() => {
    if (checkOutResult.isReady && checkOutResult.isSuccessful) {
      const filledQxItems = items.filter(
        (item) =>
          checkOutResult.filledItemMap[item.tokenId] &&
          item.marketplace === Marketplace.quix,
      )
      const sumQxFilledItemsAmount = bnSum(
        filledQxItems.map((item) => item.paymentAmount),
      )
      const opRewardPartial = opRewardData.calculateOpRewardByTotal(
        sumQxFilledItemsAmount,
      )

      // OP after checkout
      setOpRewardNumDisplay(
        opRewardPartial.div('1000000000000000').toNumber() / 1000,
      )
    } else {
      // OP before checkout
      setOpRewardNumDisplay(opRewardData.result.opRewardNum)
    }
  }, [checkOutResult, items, opRewardData])

  return (
    <BaseRow
      label={
        <span className="animate-bg text-transparent bg-clip-text animate-bg">
          Trading Reward{' '}
          {totalRewardPerMilleNum > 0 && totalRewardPerMilleNum / 10 + '%'}
        </span>
      }
      subLabel="Earn $OP when buying from Quix"
      value={
        <span>
          <span className="text-slate-500 font-normal">est.</span>{' '}
          {opRewardNumDisplay} OP
        </span>
      }
      subValue={`$${(opRewardNumDisplay * opPriceNum).toFixed(3)}`}
      image={
        <Image
          src="https://ethereum-optimism.github.io/data/OP/logo.png"
          width={48}
          height={48}
          alt="OP token"
        />
      }
      onRemoveItem={() => {}}
      refundMessage={'xxx OP was not sent'}
      status={getItemStatus({
        isTxSent,
        isTxConfirmed,
        isSuccessful: checkOutResult.isReady && checkOutResult.isSuccessful,
        isUnavailable: false,
      })}
      isRemoveble={false}
    />
  )
}

// TODO: refactor to use base row
const TokenRow: React.FC<{
  item: ItemSellOrderParams
  isTxSent: boolean | undefined
  isTxConfirmed: boolean | undefined
  isReviewingOrder: boolean | undefined
  isUnavailable: boolean | undefined
  fulfilledLog: eventOperatorBuy | undefined | null
  onRemoveItem: (tokenId: string) => void
}> = ({
  item,
  isTxSent,
  isTxConfirmed,
  isReviewingOrder,
  isUnavailable,
  fulfilledLog,
  onRemoveItem,
}) => {
  if (!item) {
    return <></>
  }

  const itemStatus = getItemStatus({
    isTxSent,
    isTxConfirmed,
    isSuccessful: !!fulfilledLog,
    isUnavailable,
  })

  // fulfilledItems

  return (
    <div
      className={`
    flex items-center justify-between
    ${itemStatus === 'Unavailable' ? 'opacity-60' : ''}
    `}
    >
      {/* left side info */}
      <div className="flex items-center">
        {itemStatus === 'Processing' && <Spinner className="mr-2 text-2xl" />}
        {itemStatus === 'Fulfilled' && (
          <BsCheckCircleFill className="mr-2 text-green-500 text-2xl" />
        )}
        {itemStatus === 'Refunded' && (
          <TooltipContainer message="The item was sold or expired before checkout">
            <MdError className="mr-2 text-yellow-500 text-2xl" />
          </TooltipContainer>
        )}
        <div className="h-16 w-16 relative">
          <ERC721Image
            src={item.image}
            alt={item.name}
            className={`rounded-md ${
              itemStatus === 'Refunded' ? 'opacity-50' : ''
            }`}
          />
          {isReviewingOrder && (
            <div
              onClick={() => onRemoveItem(item.tokenId)}
              className={`
              absolute -right-2 -top-2 
              ${
                itemStatus === 'Unavailable'
                  ? 'bg-red-500/90 hover:bg-red-400 text-white'
                  : 'text-slate-400 hover:text-rose-500 bg-white/80'
              }
              cursor-pointer rounded-full`}
            >
              <AiOutlineCloseCircle size="24" />
            </div>
          )}
        </div>
        <div className="ml-2">
          <div className="text-slate-700 font-semibold flex items-baseline">
            {/* Display only token ID on mobile */}
            <span className="hidden md:block">{item.name}</span>
            <span className="block md:hidden">#{item.tokenId}</span>

            {itemStatus === 'Refunded' && (
              <span className="font-normal text-sm ml-1 text-slate-500">
                (refunded)
              </span>
            )}
          </div>
          <div className="text-slate-500 text-sm">{item.collectionName}</div>
          {itemStatus === 'Unavailable' && (
            <div className="text-red-500 text-xs">Item is not available</div>
          )}
        </div>
      </div>

      {/* right side info */}
      <div className="text-right">
        <div className="text-slate-700 font-semibold">
          {formatEth(item.txBuyData.buy.value)} ETH
        </div>
        <div className="text-slate-500 text-sm">
          $<EthPriceInUsd wei={item.txBuyData.buy.value} />
        </div>
      </div>
    </div>
  )
}

const BaseRow: React.FC<{
  status: 'Pending' | 'Processing' | 'Fulfilled' | 'Refunded' | 'Unavailable'
  label: string | React.ReactNode
  subLabel: string | React.ReactNode
  value: string | React.ReactNode
  subValue: string | React.ReactNode
  refundMessage: string
  image: React.ReactNode
  isRemoveble: boolean
  onRemoveItem: () => void
}> = ({
  status,
  label,
  value,
  subLabel,
  subValue,
  refundMessage,
  image,
  isRemoveble,
  onRemoveItem,
}) => {
  return (
    <div className="flex items-center justify-between">
      {/* left side info */}
      <div className="flex items-center">
        {status === 'Processing' && <Spinner className="mr-2 text-2xl" />}
        {status === 'Fulfilled' && (
          <BsCheckCircleFill className="mr-2 text-green-500 text-2xl" />
        )}
        {status === 'Refunded' && (
          <TooltipContainer message={refundMessage}>
            <MdError className="mr-2 text-yellow-500 text-2xl" />
          </TooltipContainer>
        )}
        <div className="h-16 w-16 relative flex justify-center items-center">
          {image}
          {isRemoveble && (
            <div
              onClick={() => onRemoveItem()}
              className="absolute -right-2 -top-2 text-slate-400 hover:text-rose-500 bg-white/80 cursor-pointer rounded-full"
            >
              <AiOutlineCloseCircle size="24" />
            </div>
          )}
        </div>
        <div className="ml-2">
          <div className="text-slate-700 font-semibold flex items-baseline">
            {label}

            {status === 'Refunded' && (
              <span className="font-normal text-sm ml-1 text-slate-500">
                (refunded)
              </span>
            )}
          </div>
          <div className="text-slate-500 text-sm">{subLabel}</div>
        </div>
      </div>

      {/* right side info */}
      <div className="text-right">
        <div className="text-slate-700 font-semibold">{value}</div>
        <div className="text-slate-500 text-sm">{subValue}</div>
      </div>
    </div>
  )
}

function formatEth(value: BigNumberish): string {
  if (!value) {
    return ''
  }
  return formatEther(value)
}

export default CheckOutConfirmationSection
