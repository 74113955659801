import { BigNumber, BigNumberish, ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";

export function bnSum(big: BigNumberish[]): BigNumber {
  return big.reduce(
    (acc: BigNumber, curr) => BigNumber.from(acc).add(curr),
    ethers.constants.Zero,
  )
}

export function formatEtherWithDecimal(wei: BigNumberish, decimalOutput: number): string {
  const [whole, decimal] = formatEther(wei).split(".");
  return `${whole}.${decimal.substring(0, decimalOutput)}`;
}

