import Image, { ImageProps } from 'next/image'
import OptimizedImage from './OptimizedImage'

type Props = {
  src: string
  alt: string
} & ImageProps
const ERC721Image: React.FC<Props> = ({ src, alt, ...props }) => {
  if (!src) {
    return (
      <Image
        src="/placeholder.svg"
        alt={alt}
        {...props}
        width={480}
        height={480}
        objectFit="cover"
      />
    )
  }
  return (
    <OptimizedImage
      {...props}
      src={src}
      alt={alt}
      width={480}
      height={480}
      objectFit="cover"
    />
  )
}

export default ERC721Image
