import { CollectionDetailApi, ItemSellOrderParams, Marketplace } from '@/types'
import Spinner from './Spinner'
import { BsCheckCircleFill, BsCheckCircle } from 'react-icons/bs'
import { formatEther } from 'ethers/lib/utils'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import ERC721Image from './ERC721Image'
import TooltipContainer from './TooltipContainer'
import { useAccount } from 'wagmi'
import { QxOpRewardResult } from '@/hooks/useQxOpRewards'
import { getAssetSourceLink } from '@/libs/marketplace'
import Icon from './Icon'

type ERC721ItemProps = {
  collectionDetail: CollectionDetailApi | null
  item: ItemSellOrderParams
  onClick: () => void
  isSelected: boolean
  isBuying?: boolean
  isSold?: boolean
}
const ERC721Item: React.FC<ERC721ItemProps> = ({
  collectionDetail,
  item,
  onClick,
  isSelected,
  isBuying,
  isSold,
}) => {
  const { trackEvent } = useAnalytics()

  const transitionClass = 'transition duration-200'
  const isSoldClass = isSold
    ? 'cursor-not-allowed opacity-50'
    : 'cursor-pointer'
  const isSelectedClass = isSelected
    ? 'border-2 border-primary shadow-xl shadow-slate-500/20 -translate-y-2 scale-[1.03]'
    : 'border-2 border-slate-200 hover:border-slate-400'

  const canBeBought = !!item.txBuyData

  return (
    <div
      key={item.tokenId}
      onClick={isSold || !canBeBought ? () => {} : onClick}
      className={`${isSoldClass} ${isSelectedClass} ${transitionClass} relative group transition-shadow	inline-block overflow-hidden bg-white border-solid rounded-md`}
    >
      <div className="relative overflow-hidden">
        <div className="absolute top-2 left-2 z-10 w-6 h-6">
          <a
            href={getAssetSourceLink(
              item.marketplace,
              item.chainId,
              item.collectionAddress,
              item.tokenId,
            )}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              trackEvent(AnalyticsEvent.ItemExternalSourceClicked)
              e.stopPropagation()
            }}
          >
            {item.marketplace === Marketplace.opensea ? (
              <Icon
                name="opensea"
                size={24}
                alt="Go to OpenSea"
                className="opacity-50 hover:opacity-75"
              />
            ) : (
              <Icon
                name="quix"
                size={24}
                alt="Go to Quix"
                className="opacity-50 hover:opacity-75"
              />
            )}
          </a>
        </div>
        <ERC721Image
          src={item.image || collectionDetail?.image || ''}
          alt={item.name}
        />
      </div>
      <div className="py-1 px-2">
        <div className="font-semibold text-slate-700">
          {item.name || item.tokenId}
        </div>
        {canBeBought && (
          <div className="text-sm font-medium text-slate-500">
            Ξ {formatEther(item.paymentAmount)}
          </div>
        )}
        {canBeBought && (
          <ItemSelectStatus
            isSelected={isSelected}
            isBuying={isBuying}
            isSold={isSold}
          />
        )}
      </div>
    </div>
  )
}

type ItemSelectStatus = {
  isSelected: boolean
  isBuying?: boolean
  isSold?: boolean
}
const ItemSelectStatus: React.FC<ItemSelectStatus> = ({
  isSelected,
  isBuying,
  isSold,
}) => {
  const selectedClass =
    isSelected && !isBuying ? 'text-primary' : 'text-slate-600'

  const className = `h-6 flex justify-end items-center ${selectedClass}`
  if (isSold) {
    return (
      <div className={className}>
        <span className="font-semibold text-white text-sm bg-slate-400 px-2 rounded-md">
          SOLD
        </span>
      </div>
    )
  }

  if (isBuying) {
    return (
      <div className={className}>
        <Spinner />
      </div>
    )
  }

  if (isSelected) {
    return (
      <div className={className}>
        <BsCheckCircleFill />
      </div>
    )
  }

  return (
    <div className={className}>
      <BsCheckCircle />
    </div>
  )
}

const QuixRewardInformation: React.FC<{ quixOpReward: QxOpRewardResult }> = ({
  quixOpReward,
}) => {
  const { address } = useAccount()

  const {
    totalRewardPerMilleNum,
    isEligibleForOpOgReward,
    collectionRewardPerMilleNum,
    opOgRewardPerMilleNum,
    baseRewardPerMilleNum,
  } = quixOpReward

  const isRewardDataAvail = totalRewardPerMilleNum > 0

  const TooltipContent = () => (
    <div>
      <div className="font-bold">
        Total $OP Reward: {totalRewardPerMilleNum / 10}%
      </div>
      <div className="font-medium text-white/75">
        • Collection Boost: {collectionRewardPerMilleNum / 10}%
      </div>
      <div
        className={`font-medium text-white/75 ${
          !isEligibleForOpOgReward ? 'line-through opacity-75' : ''
        }`}
      >
        • Optimism OG: {opOgRewardPerMilleNum / 10}%
      </div>
      <div className="font-medium text-white/75">
        • Base: {baseRewardPerMilleNum / 10}%
      </div>

      <div className="text-white/75 mt-2">
        $OP Reward earned when buying from Quix
      </div>
    </div>
  )

  const EmptyTooltipContent = () => {
    return (
      <div className="text-white/75">
        {address
          ? 'Loading Quix $OP reward information...'
          : 'Connect wallet to see Quix $OP rewards info'}
      </div>
    )
  }

  return (
    <TooltipContainer
      message={isRewardDataAvail ? <TooltipContent /> : <EmptyTooltipContent />}
      className="w-48"
      panelClassName={`${
        isRewardDataAvail ? '-top-44' : '-top-28'
      } left-2 mb-1`}
    >
      <div
        className={`
          absolute left-2 bottom-4 z-20 py-1 px-2 ${
            isRewardDataAvail ? 'w-32' : 'w-24'
          }
          bg-red-500/60 text-white rounded 
          group-hover:bg-red-500 
          transition-colors duration-150
          font-bold text-xs text-center`}
      >
        {isRewardDataAvail
          ? `${totalRewardPerMilleNum / 10}% $OP reward`
          : `$OP reward`}
      </div>
    </TooltipContainer>
  )
}

export default ERC721Item
