import { BigNumber, ethers } from 'ethers'
import { RawEthersLog, eventRouterSweep, eventRouterBuy, eventOperatorBuy, SweepResult } from './types'

export const ROUTER_BUY_TOPIC =
  '0x7a212d757c7290587e1c8f7100a01a3d09466d58945058b6f22a179013475a90'
export const ROUTER_SWEEP_TOPIC =
  '0x70fccfe4f0dfb2139d473b26e7f4acf4a980d78df6d1a27bf18539bc4069835e'
export const OPERATOR_BUY_TOPIC =
  '0x700b5bdfe7a3339cdd7f66d3169b15b1df0e29719002c4176505f98cc850923d'

export const parseLogs = (logs: RawEthersLog[]): SweepResult => {
  if (!logs) {
    return null
  }

  const abi = [
    // Router events
    'event Buy(address paymentToken, uint256 paymentAmount, address indexed receiver, address indexed collection, uint256 indexed tokenId, uint256 tokenAmount)',
    'event Sweep(address paymentToken, uint256 paymentAmount, address indexed receiver, uint256 fulfillRequest, uint256 fulfilledAmount)',

    // Operator events
    'event Buy(uint256 indexed marketId, uint256 marketVersion, address indexed collection, uint256 tokenId, uint256 tokenAmount, address indexed receiver, address paymentToken, uint256 paymentAmount)',
  ]
  const routerInterface = new ethers.utils.Interface(abi)

  const parsedLogs = logs
    .map((log) => {
      try {
        return routerInterface.parseLog(log)
      } catch (error) {
        return null
      }
    })
    .filter((log) => log !== null)

  // assuming there will be only one router log for each tx hash
  let routerSweepLog: eventRouterSweep | null = null
  let routerBuyLog: eventRouterBuy | null = null
  const operatorLogs: eventOperatorBuy[] = []

  for (let i = 0; i < parsedLogs.length; i++) {
    const log = parsedLogs[i]
    if (!log) {
      continue
    }

    const { args, topic } = log
    if (!args || !topic) {
      continue
    }

    if (topic === ROUTER_BUY_TOPIC) {
      routerBuyLog = {
        paymentToken: args?.paymentToken,
        paymentAmount: args?.paymentAmount,
        receiver: args?.receiver,
        collection: args?.collection,
        tokenId: args?.tokenId,
        tokenAmount: args?.tokenAmount,
      }
    } else if (topic === ROUTER_SWEEP_TOPIC) {
      routerSweepLog = {
        paymentToken: args?.paymentToken,
        paymentAmount: args?.paymentAmount,
        receiver: args?.receiver,
        fulfillRequest: args?.fulfillRequest,
        fulfilledAmount: args?.fulfilledAmount,
      }
    } else if (topic === OPERATOR_BUY_TOPIC) {
      operatorLogs.push({
        marketId: args?.marketId,
        marketVersion: args?.marketVersion,
        collection: args?.collection,
        tokenId: args?.tokenId,
        tokenAmount: args?.tokenAmount,
        receiver: args?.receiver,
        paymentToken: args?.paymentToken,
        paymentAmount: args?.paymentAmount,
      })
    }
  }

  if (routerBuyLog) {
    return {
      paymentToken: routerBuyLog.paymentToken,
      paymentAmount: routerBuyLog.paymentAmount,
      receiver: routerBuyLog.receiver,
      fulfillRequest: BigNumber.from(1),
      fulfilledAmount: BigNumber.from(1),
      operatorLogs,
    }
  }

  if (routerSweepLog) {
    return {
      paymentToken: routerSweepLog.paymentToken,
      paymentAmount: routerSweepLog.paymentAmount,
      receiver: routerSweepLog.receiver,
      fulfillRequest: routerSweepLog.fulfillRequest,
      fulfilledAmount: routerSweepLog.fulfilledAmount,
      operatorLogs,
    }
  }

  return null
}
