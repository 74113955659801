import { captureError } from "@/libs/sentry"
import { useState, useEffect } from "react"


export const useForceUpdate = (condition: boolean, updateIntervalMs = 1000) => {
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    let timer: NodeJS.Timeout

    try {
      if (condition) {
        timer = setTimeout(() => {
          setValue((val) => val + 1)
        }, updateIntervalMs)
      }
    } catch (error) {
      console.error(error)
      captureError('error', error)
    }

    // clear timeout when unmounted
    return () => {
      clearTimeout(timer)
    }
  }, [condition, value, updateIntervalMs])
}