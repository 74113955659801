// ref: https://blog.logrocket.com/accessing-previous-props-state-react-hooks/

import { useEffect, useRef } from 'react'

function usePreviousValue<T>(value: T) {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePreviousValue
