import { Popover } from '@headlessui/react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  message: string | React.ReactNode
  children: React.ReactNode
  className?: string
  panelClassName?: string
}
const TooltipContainer: React.FC<Props> = ({
  message,
  children,
  className,
  panelClassName,
}) => {
  const [isShow, setIsShow] = useState(false)

  return (
    <Popover className="relative inline-block">
      <div
        onMouseEnter={() => setIsShow(true)}
        onMouseLeave={() => setIsShow(false)}
      >
        {children}
      </div>

      {isShow && (
        <Popover.Panel
          static
          className={twMerge('absolute z-50 left-6 -top-8', panelClassName)}
        >
          <div className="overflow-hidden rounded shadow-lg">
            <div
              className={twMerge(
                'relative font-normal bg-slate-800/90 px-6 py-3 text-white text-xs w-48',
                className,
              )}
            >
              {message}
            </div>
          </div>
        </Popover.Panel>
      )}
    </Popover>
  )
}

export default TooltipContainer
