/* eslint-disable @next/next/no-img-element */
import { cloudflareImageLoader } from '@/libs/cloudflare'
import Image, { ImageProps } from 'next/image'

// exclude from cloudflare resizing, can use partial match string
const EXCLUDED_IMAGE_RESIZE_URLS = [
  // cloudflare cannot resize animated webp yet
  'https://fanbase-1.s3.amazonaws.com/quixotic-collection-profile/motorhead_uDSVrVi.webp',
  // too many frames
  'https://fanbase-1.s3.amazonaws.com/quixotic-collection-profile/vllo_uNNIu0u.GIF',
  // ERROR 9413: Could not resize the image: The animation is too large.
  // Animation resolution is 720×720, and at this size the maximum number of animation
  // frames allowed is 192 (total area of all frames together must be less than 100000000)
  'https://fanbase-1.s3.amazonaws.com/quixotic-collection-profile/vllo.GIF',
  // somehow some of OpenSea Sea(C)DN images returns 422 error when resizing through cloudflare
  'https://i.seadn.io',
  // MOAYC, that failed to resize in cloudflare
  'bafybeigqzsfs7n2d7b2mdevtyspryb73k4a7cjn56q5hduv6n6n2c5pj7e',
]

const EXCLUDED_VERCEL_URLS = [
  // vercel cannot resize some opensea gif images, better skip them
  'https://i.seadn.io',
]

type Props = ImageProps

const OptimizedImage: React.FC<Props> = ({ src, alt, ...props }) => {
  if (src === '') {
    return <Image {...props} src="/bluesweep-logo.svg" alt={alt} />
  }

  if (
    typeof src === 'string' &&
    EXCLUDED_VERCEL_URLS.some((excluded) => src.includes(excluded))
  ) {
    // fallback to use native img tag

    // remove objectFit from props, it's not supported by native img tag
    const { objectFit, ...imgProps } = props
    return (
      <div className="inline-block">
        <img {...imgProps} src={src} alt={alt} />
      </div>
    )
  }

  if (
    typeof src === 'string' &&
    EXCLUDED_IMAGE_RESIZE_URLS.some((excluded) => src.includes(excluded))
  ) {
    // fallback to use default next image loader
    return <Image {...props} src={src} alt={alt} />
  }

  return <Image {...props} loader={cloudflareImageLoader} src={src} alt={alt} />
}

export default OptimizedImage
