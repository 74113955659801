import { AiOutlineClose } from 'react-icons/ai'
import { twMerge } from 'tailwind-merge'

type ButtonProps = {
  isOn: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

const SweepModeButton: React.FC<ButtonProps> = ({
  isOn,
  onClick,
  className,
}) => {
  const stateClass = isOn
    ? 'hover:text-rose-500'
    : 'hover:text-primary hover:border-primary'
  const colorClass = 'text-slate-500 bg-white border border-slate-400 border-2'
  const sizeClass = 'px-6 py-3'

  const baseClassName = `flex min-w-max font-medium items-center rounded-md justify-self-center justify-center ${colorClass} ${sizeClass} ${stateClass}`
  return (
    <button onClick={onClick} className={twMerge(baseClassName, className)}>
      {isOn ? (
        <>
          <AiOutlineClose />
          <div className="inline-block ml-2">Stop Sweep Mode</div>
        </>
      ) : (
        <>
          🧹 <div className="inline-block ml-2">Sweep Mode</div>
        </>
      )}
    </button>
  )
}

export default SweepModeButton
