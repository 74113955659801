import { BiLoaderAlt } from 'react-icons/bi'

type Props = {
  className?: string
}
const Spinner: React.FC<Props> = ({ className }) => {
  return (
    <BiLoaderAlt className={`animate-spin leading-16 text-lg ${className}`} />
  )
}

export default Spinner
