import { MULTICALL3_ADDRESS, OPTIMISM_ETH_WHALE_ADDRESS } from '@/constants'
import { Multicall3__factory } from '@/contracts'
import { ItemSellOrderParams } from '@/types'
import { BigNumber, ethers } from 'ethers'
import { bnSum } from '../bigNumberMath'

export type OrderValidationResult = {
  tokenId: string
  success: boolean
}

export const validateOrdersSimple = async (
  itemList: ItemSellOrderParams[],
  provider: ethers.providers.Provider,
): Promise<OrderValidationResult[] | null> => {
  const multicall3 = Multicall3__factory.connect(MULTICALL3_ADDRESS, provider)

  const callDatas = itemList.map((item) => {
    const buyData = item.txBuyData.buy
    return {
      target: buyData.to,
      allowFailure: true,
      value: buyData.value,
      callData: buyData.data,
    }
  })

  // TODO: support other chains
  const results = await multicall3.callStatic.aggregate3Value(callDatas, {
    from: OPTIMISM_ETH_WHALE_ADDRESS,
    value: bnSum(itemList.map((item) => item.txBuyData.buy.value)),
  })

  return itemList.map((item, index) => ({
    tokenId: item.tokenId,
    success: results[index].success,
  }))
}
