export type TxHash = string
export type Address = string
export type TokenId = string

export enum CHAINS {
  ETHEREUM = 'ETHEREUM',
  OPTIMISM = 'OPTIMISM',
  ARBITRUM = 'ARBITRUM',
}

export type CollectionDetail = {
  address: string
  name: string
  symbol: string
  owner: object
  image_url: string
  royalty_per_mille: number
  floor_price: {
    ETH: number
  }
}
export type SellOrder = {
  start_time: number
  expiration: number
  price: number
  eth_price: number
  usd_price: number
  created_at_block_number: number
  signature: string
}

export type TxBuyData = {
  buy: {
    to: string
    value: string
    data: string
  }
  sweep: {
    prefix: string
    payload: string
  }
}

export type CollectionStatsParams = {
  volOneDay: number
  volSevenDay: number
  volThirtyDay: number
  volTotal: number

  volChangePercentageOneDay: number
  volChangePercentageSevenDay: number
  volChangePercentageThirtyDay: number

  floorPrice: number
  ownerTotal: number
  supplyTotal: number
}

export enum ItemType {
  NativeCurrency = 0,
  ERC20 = 1,
  ERC721 = 2,
}

export enum Marketplace {
  opensea = 'opensea',
  quix = 'quix',
}

export type ItemSellOrderApi = ItemSellOrderParams & {
  collection: CollectionDetailApi
}

export type ItemSellOrderParams = {
  /** Unique order identifier, ex. Seaport = OrderHash */
  orderId: string
  marketplace: Marketplace
  chainId: number
  type: ItemType
  tokenId: string
  name: string
  image: string
  paymentAmount: string
  txBuyData: TxBuyData
  collectionName: string
  collectionAddress: string
  listedAt: number
  expiresAt: number

  seller: string
  sellerReverseEns: string | null

  // generated fields
  sourceUrl: string
}

export type CollectionParams = {
  chainId: number
  address: Address
  itemType: ItemType
  name: string
  symbol: string
  slug: string | null
  verified: boolean
  description: string
  image: string
  website: string | null
  twitter: string | null
  discord: string | null
  quixotic: string
  etherscan: string
  apetimismPad: string | null
  launchedAt: string | null

  // TODO: add more
}

export type FeaturedCollection = {
  collection: CollectionParams
  collectionStats: CollectionStatsParams
  floorItems: ItemSellOrderParams[]
}

// ## Sample Data:
//
// {
//   "data": {
//       "volBlueSweep": "0",
//       "volQxV1to5": "70000000443547794",
//       "volQxV6": "0",
//       "latestUpdateBlock": 23140913
//   }
// }
export type WalletVolumes = {
  volBlueSweep: string
  volQxV1to5: string
  volQxV6: string
  latestUpdateBlock: number
}

export type Wallet = {
  address: Address
  username: string | null
  reverseEns: string | null
}

export type PaymentToken = {
  address: Address
  name: string
  symbol: string
  decimals: number
}

export type SaleActivityParams = {
  marketplace: Marketplace
  orderId: string
  chainId: number
  txHash: TxHash
  tokenId: string
  tokenName: string
  tokenImage: string
  paymentAmountEth: string
  paymentAmount: string
  paymentToken: PaymentToken
  matchedAt: string
  buyer: Wallet
  seller: Wallet
  // TAKER_BUY means the buyer takes the listed sell order
  // TAKER_SELL means the seller sells to the maker buy oder
  type: 'TAKER_BUY' | 'TAKER_SELL'
}

export enum OrderType {
  ASC,
  DESC,
  Sort,
}

export type CollectionDetailApi = CollectionDetailV2 & {
  verified: boolean
  etherscan: string
  apetimismPad: string | undefined | null
  launchedAt: string | undefined | null
}

export interface CollectionDetailV2 {
  // unique
  chain: CHAINS
  address: string

  name: string
  symbol: string
  slug: string
  detail: string
  verified: boolean
  verifiedSources: Marketplace[]

  // stats
  floorPrice: number
  ownerTotal: number
  supplyTotal: number
  volChangePercentageOneDay: number
  volChangePercentageSevenDay: number
  volChangePercentageThirtyDay: number
  volOneDay: number
  volSevenDay: number
  volThirtyDay: number
  volTotal: number

  // socail / link
  twitter: string | null
  website: string | null
  discord: string | null
  etherscan: string
  image: string | null
}
