import { CHAIN_IDS } from "@/constants";
import { Marketplace } from "@/types";

const openSeaChainMap = {
  [CHAIN_IDS.OPTIMISM]: 'optimism',
  [CHAIN_IDS.ARBITRUM]: 'arbitrum',
  [CHAIN_IDS.MAINNET]: 'ethereum',
}

export const getAssetSourceLink = (marketplace: Marketplace, chainId: number, contractAddress: string, tokenId: string) => {
  if (marketplace === Marketplace.opensea) {
    return `https://opensea.io/assets/${openSeaChainMap[chainId]}/${
      contractAddress
    }/${tokenId}`
  }

  if (marketplace === Marketplace.quix) {
    return `https://qx.app/asset/${contractAddress}/${tokenId}` 
  }

  console.error('Unknown marketplace', marketplace)
  return ''
}