import { formatRelativeTime } from '@/libs/datetime'
import { useEffect, useState } from 'react'
import { TbRefresh } from 'react-icons/tb'
import { DateTime } from 'luxon'

type ReloadProps = {
  handleLoadResources: Function
  isLoading: boolean
}

type TimerProps = {
  counter: number
}

const Reload: React.FC<ReloadProps> = ({ handleLoadResources, isLoading }) => {
  const [loading, reLoading] = useState<boolean>(isLoading)
  const [counter, setCounter] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [counter])

  useEffect(() => {
    reLoading(isLoading)
  }, [isLoading])

  const handleReload = async () => {
    reLoading(true)
    await handleLoadResources()
    reLoading(false)
    setCounter(0)
  }

  return (
    <div className="flex flex-row items-center">
      <TbRefresh
        onClick={handleReload}
        size="28"
        color="text-slate-500"
        className={`${
          loading ? 'animate-spin' : ''
        } cursor-pointer text-slate-500 hover:text-slate-400 ease-in duration-100`}
      />
      <span className="ml-2 text-state-500 font-light text-md">
        {loading ? `Loading...` : `Last update ${Timer(counter)}`}
      </span>
    </div>
  )
}

const Timer = (counter: number): string => {
  if (counter < 60) {
    return `${counter}s ago`
  } else {
    let minute = Math.floor(counter / 60)
    return minute > 1 ? `${minute} minutes ago` : `${minute} minute ago`
  }
}

export default Reload
