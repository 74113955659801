import { CHAINS } from "@/types"
import { BigNumber } from "ethers"
import { formatEther } from "ethers/lib/utils"
import { getEtherscanUrlByChain } from "./transaction"

export const genTwitterLinkToShare = ({
  collectionName,
  filledItems,
  totalPayment,
  collectionTwitter,
  txHash,
  chain,
  percentageGasSaved,
}: {
  collectionName: string
  filledItems: number
  totalPayment: BigNumber
  collectionTwitter: string | null
  txHash: string
  chain: CHAINS
  percentageGasSaved?: number
}) => {
  const twitterHandle = collectionTwitter ? collectionTwitter.split('/').pop() : ''

  const sharingText = `I just swept ${collectionName} with @bluesweep_xyz ✨

🧹 Sweep: ${filledItems} NFTs
💰 For: ${formatEther(totalPayment)} ETH
${percentageGasSaved ? '⛽️ Gas saved: ' + percentageGasSaved + '%' : ''}

🔗 Etherscan: ${getEtherscanUrlByChain(txHash, chain)}
    
${twitterHandle && `@${twitterHandle} `}#bluesweep`

  return (
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(sharingText)}&url=https://www.bluesweep.xyz`
  )
}

// Sample from Gem
// https://twitter.com/intent/tweet?text=I%20just%20swept%20the%20Parallel%20Alpha%20floor%20with%20@gemxyz%20%F0%9F%92%8E%F0%9F%A7%B9%0A%F0%9F%9B%92%20Bought:%201%20items%20for%200.0019%20ETH%0A%F0%9F%92%B0%20Gas%20savings:%20$0.00%0A%0Ahttps://etherscan.io/tx/0x034f032721f4545770e1b07ffacfaa54992043ce9a2afb1669293b8ea3b5b7d7%20&url=https://www.gem.xyz/