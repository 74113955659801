// L2 Gas calculation
// ref: https://github.com/bluematterlabs/l2calc/blob/main/src/fees/calculator.ts
//
// TODO: refactor to share the module with l2calc
import { BigNumber, BigNumberish, ethers } from 'ethers'

const L1_OVERHEAD_GAS = 3188 // overhead + signature = (2100 + 68 * 16)
const NORMAL_TIPS = ethers.utils.parseUnits('1.5', 'gwei')

// TODO: include L2 execution fee, ignore for now as it is not significant
export const calculateTxCost = (txData: string, l1GasPrice: BigNumberish) => {
  const l1GasUsage = calculateL1GasUsageForCallData(txData)
  const l1GasUsageField = BigNumber.from(l1GasUsage)
  // const l1GasPrice = await getL1GasPrice()
  const l1SecurityFee = l1GasUsageField.mul(l1GasPrice)

  return l1SecurityFee
}

export const calculateL1GasUsageForCallData = (payload: string) => {
  let zeroCount = 0
  let nonZeroCount = 0
  const payloadBytes = hexToBytes(payload)
  // console.log(payload.length)
  // console.log(hexToBytes(payload))

  for (let index = 0; index < payloadBytes.length; index++) {
    const payloadByte = payloadBytes[index]
    if (payloadByte === 0) {
      zeroCount += 1
    } else {
      nonZeroCount += 1
    }
  }
  const calldataGasUsage = zeroCount * 4 + nonZeroCount * 16
  // console.log({ zeroCount, nonZeroCount, calldataGasUsage })
  return L1_OVERHEAD_GAS + calldataGasUsage
}

export const getL1GasPrice = async (rpcUrl: string, chainId: number) => {
  if (chainId !== 1) {
    console.warn('getL1GasPrice only works on mainnet')
  }

  const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
  const latestBlockNumber = await provider.getBlockNumber()
  console.log({ latestBlockNumber })
  const latestBlock = await provider.getBlock(latestBlockNumber)
  console.log({ baseFeePerGas: latestBlock.baseFeePerGas!.toString() })

  return latestBlock.baseFeePerGas!.add(NORMAL_TIPS)
}

function hexToBytes(hex: string) {
  hex = hex.replace('0x', '') // strip off 0x
  for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16))
  return bytes
}
