import { twMerge } from 'tailwind-merge'
import Spinner from './Spinner'

type ButtonProps = {
  type?: 'primary' | 'line' | 'lineAnimate' | 'base' | 'danger' | 'dangerLine'
  size?: 'sm' | 'md'
  loading?: boolean
  shadow?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode | string
  className?: string
}

const typeClasses: Record<string, string> = {
  primary:
    ' text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:from-[#933de3] hover:to-[#4487f2] hover:scale-[1.03] active:from-purple-700 active:to-blue-600 active:scale-[0.97]',
  line: 'text-slate-500 bg-white border border-gray-400 hover:opacity-80',
  lineAnimate:
    'text-slate-500 bg-white border border-slate-400 hover:opacity-80 hover:scale-[1.03] active:text-slate-600 active:border-slate-500 active:scale-100',
  base: 'bg-primary text-white hover:opacity-90',
  danger: 'bg-red-400 text-white hover:opacity-90',
  dangerLine: 'text-red-400 bg-white border border-red-400 hover:opacity-80',
}

const sizeClasses: Record<string, string> = {
  sm: 'px-6 py-3',
  md: 'px-8 py-4 font-semibold',
}

const Button: React.FC<ButtonProps> = ({
  type = 'primary',
  size = 'md',
  loading,
  shadow,
  onClick,
  children,
  className,
}) => {
  const loadingClass = loading ? 'bg-slate-300' : typeClasses[type]
  const actionClass = 'transition-transform '
  const shadowClass = shadow ? 'drop-shadow-md' : ''

  const baseClassName = `flex items-center justify-center rounded-md justify-self-center ${sizeClasses[size]} ${shadowClass} ${loadingClass} ${actionClass}`
  return (
    <button
      disabled={loading}
      onClick={onClick}
      className={twMerge(baseClassName, className)}
    >
      {loading && (
        <div className="text-lg mr-2">
          <Spinner />
        </div>
      )}
      {children}
    </button>
  )
}

export default Button
