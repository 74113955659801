export type TabName = 'items' | 'activities'

type Props = {
  selectedTab: TabName
  onSelectTab: (tab: TabName) => void
}
const MobileTabMenu: React.FC<Props> = ({ onSelectTab, selectedTab }) => {
  const baseClass = 'relative z-10 cursor-pointer pb-2 relative'
  const selectedClass = 'text-primary font-bold'
  const unselectedClass = 'text-slate-400 font-medium'
  return (
    <div className="flex space-x-4">
      <div
        onClick={() => onSelectTab('items')}
        className={`${baseClass} ${
          selectedTab === 'items' ? selectedClass : unselectedClass
        }`}
      >
        Items
        {selectedTab === 'items' && (
          <div className="h-1 absolute bottom-0 bg-primary rounded -left-1 -right-1"></div>
        )}
      </div>
      <div
        onClick={() => onSelectTab('activities')}
        className={`${baseClass} ${
          selectedTab === 'activities' ? selectedClass : unselectedClass
        }`}
      >
        Live Market Activity
        {selectedTab === 'activities' && (
          <div className="h-1 absolute bottom-0 bg-primary rounded -left-1 -right-1"></div>
        )}
      </div>

      {/* Gray Border bottom */}
      <div className="absolute border-solid border-b-2 border-slate-200 bottom-[1px] -left-8 -right-4"></div>
    </div>
  )
}

export default MobileTabMenu
