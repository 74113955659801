import { CollectionDetailV2 } from '@/types'

type CollectionInfoSectionProps = {
  collectionDetail: CollectionDetailV2 | null
}

const CollectionInfoSection: React.FC<CollectionInfoSectionProps> = ({
  collectionDetail,
}) => {
  const panelClass = 'mr-6 md:mr-8 lg:mr-8 xl:mr-12 min-w-max'
  const labelClass = 'md:text-xs text-slate-400'
  const valueClass = 'text-slate-600 font-medium'
  return (
    // use height to hide the overflow stats on the second line
    <ul className="h-12 md:h-10 flex flex-wrap overflow-y-hidden w-full">
      <li className={panelClass}>
        <div className={labelClass}>Floor Price</div>
        <div className={valueClass}>
          Ξ {collectionDetail?.floorPrice || 'N/A'}
        </div>
      </li>
      <li className={`${panelClass}`}>
        <div className={labelClass}>Total Volume</div>
        <div className={valueClass}>
          Ξ {collectionDetail?.volTotal?.toFixed(2)}
        </div>
      </li>
      <li className={panelClass}>
        <div className={labelClass}>Supply</div>
        <div className={valueClass}>
          {collectionDetail?.supplyTotal?.toLocaleString('en-US')}
        </div>
      </li>
      <li className={panelClass}>
        <div className={labelClass}>Holder</div>
        <div className={valueClass}>
          {collectionDetail?.ownerTotal?.toLocaleString('en-US')}
        </div>
      </li>
    </ul>
  )
}

export default CollectionInfoSection
