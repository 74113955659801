import type { NextPage } from 'next'
import { ethers } from 'ethers'
import {
  CollectionDetailApi,
  ItemSellOrderParams,
  TokenId,
  TxHash,
} from '@/types'
import { useEffect, useState } from 'react'
import { useSigner, useNetwork, useSwitchNetwork, useAccount } from 'wagmi'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import toast from 'react-hot-toast'
import { useRouter } from 'next/router'

import CollectionItemsSection from '@/sections/CollectionItemsSection'
import CheckOutSection from '@/sections/CheckOutSection'
import HeaderSection from '@/sections/HeaderSection'
import FooterSection from '@/sections/FooterSection'
import { useCollectionTokens } from '@/hooks/useCollectionTokens'
import { captureMessage } from '@/libs/sentry'
import { errNotEnoughEth, humanifyWeb3Error } from '@/libs/web3Error'
import SweepModeControlSection from '@/sections/SweepModeControlSection'
import SweepModeButton from '@/components/SweepModeButton'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import CheckOutConfirmationSection from '@/sections/CheckOutConfirmationSection'
import {
  computeTxData,
  OrderValidationResult,
  parseLogs,
  validateOrdersSimple,
} from '@/libs/bluesweep-sdk'
import {
  getSignedTCVersion,
  getTCMessage,
  saveSignedTC,
} from '@/libs/termsAndConditions'
import LatestActivitiesSection from '@/sections/LatestActivitiesSection'
import { useCollectionActivities } from '@/hooks/useCollectionActivities'
import {
  BLUESWEEP_OPERATOR_CONTRACT,
  MAX_SWEEP_ITEMS,
  MULTICALL3_ADDRESS,
  OPTIMISM_ETH_WHALE_ADDRESS,
} from '@/constants'
import { Transition } from '@headlessui/react'
import CollectionHeaderSection from '@/sections/CollectionHeaderSection'
import MobileTabMenu, { TabName } from '@/components/MobileTabMenu'
import useBounce from '@/hooks/useBounce'
import EthPriceInUsd from '@/components/EthPriceInUsd'
import { useSweepGasInfo } from '@/hooks/useSweepGasInfo'
import { shortAddress } from '@/libs/address'
import { useCollectionDetail } from '@/hooks/useCollectionDetail'
import Reload from '@/components/Reload'

// TODO: Load more on scroll down
// TODO: set the sold items correctly??? => maybe no need as the sold really means sold
// TODO: auto refresh the list?

const DEFAULT_SELECTED_ITEMS = { itemList: [], itemSet: new Set<string>() }
export const RECENT_VIEW = 'recentView'

const Collection: NextPage = () => {
  const router = useRouter()
  const { collectionName } = router.query
  const { trackEvent } = useAnalytics()

  const { styles, trigger } = useBounce({})
  const handleScrollAndGuideToConnectWallet = (delayAnimationMs = 600) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    setTimeout(() => {
      trigger()
    }, delayAnimationMs)
  }

  const [selectedTab, setSelectedTab] = useState<TabName>('items')
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()

  const [isSweepMode, setIsSweepMode] = useState(false)
  const [isReviewingOrder, setIsReviewingOrder] = useState<boolean>(false)
  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false)

  // result = validation result of each item in the cart
  const [cartValidation, setCartValidation] = useState<{
    validating: boolean
    result: OrderValidationResult[] | null
  }>({ validating: false, result: null })

  const [checkoutTxHash, setCheckoutTxHash] = useState<TxHash>('')
  const [selectedItems, setSelectedItems] = useState<{
    itemList: ItemSellOrderParams[]
    itemSet: Set<TokenId>
  }>(DEFAULT_SELECTED_ITEMS)
  const [buyingItems, setBuyingItems] = useState<Record<string, boolean>>({})
  const [soldItems, setSoldItems] = useState<Record<TokenId, boolean>>({})
  const [itemMaps, setItemMaps] = useState<Record<string, ItemSellOrderParams>>(
    {},
  )

  // use Record<TxHash, boolean> to prevent the conflict for multiple checkout
  const [isTxSent, setIsTxSent] = useState<Record<TxHash, boolean>>({})
  const [isTxConfirmed, setIsTxConfirmed] = useState<Record<TxHash, boolean>>(
    {},
  )
  const [txReceipts, setTxReceipts] = useState<
    Record<TxHash, ethers.providers.TransactionReceipt>
  >({})

  const { data: signer, isError, isLoading } = useSigner()
  const { connector, address } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()

  const { collectionSales, error, loading, handleReload } =
    useCollectionTokens(collectionName)
  const { data: collectionDetail } = useCollectionDetail(collectionName)
  const {
    data: activities,
    error: activityError,
    isLoading: activitiesLoading,
    optimisticUpdate: activitiesOptimisticUpdate,
  } = useCollectionActivities(collectionName)

  // Send selectedItems and itemMaps separately to prevent infinite update
  // TODO: investigate more why this happens
  const { sweepGasInfo } = useSweepGasInfo(selectedItems.itemList)

  useEffect(() => {
    if (collectionDetail !== null) {
      let recentItems: CollectionDetailApi[] =
        localStorage.getItem(RECENT_VIEW) != null
          ? JSON.parse(localStorage.getItem(RECENT_VIEW) as string)
          : []
      if (recentItems.length >= 3) {
        recentItems.shift()
      }
      recentItems.push(collectionDetail as CollectionDetailApi)
      recentItems = recentItems.reduce((prev, obj) => {
        if (!prev.some((obj2) => obj.slug === obj2.slug)) {
          prev.push(obj)
        }
        return prev
      }, [] as CollectionDetailApi[])
      localStorage.setItem(RECENT_VIEW, JSON.stringify(recentItems))
    }
  }, [collectionDetail])

  useEffect(() => {
    // TODO: should move to inside swr to prevent unnecessary render
    const maps: Record<string, ItemSellOrderParams> = collectionSales?.reduce(
      (prev, item) => ({ ...prev, [item.tokenId]: item }),
      {},
    )

    // merge to keep all the data, even if the item is sold
    // or else it will crash when we access the sold data
    setItemMaps((prevItemMaps) => ({ ...prevItemMaps, ...maps }))
  }, [collectionSales])

  // if (loading) {
  //   return <div>loading..</div>
  // }

  if (error) {
    console.error(error)
    captureMessage('warning', 'Collection page error', {
      error: { message: error },
    })
  }

  // TODO: handle multiple collections
  // Select/unselect
  const handleSelectItem = (tokenId: string, forceRemove = false) => {
    if (isCheckingOut) {
      toast.error('please finish checking out first', {
        position: 'top-center',
      })
      return
    }

    // Stop sweep mode if the item is manually selected
    setIsSweepMode(false)

    const found = selectedItems.itemSet.has(tokenId)

    if (found || forceRemove) {
      // remove
      setSelectedItems((prev) => {
        const newItemList = prev.itemList.filter(
          (selected) => selected.tokenId !== tokenId,
        )
        return {
          itemList: newItemList,
          itemSet: new Set(newItemList.map((item) => item.tokenId)),
        }
      })
    } else {
      // add
      setSelectedItems((prev) => {
        if (prev.itemList.length >= MAX_SWEEP_ITEMS) {
          toast.error('Selected items must not exceed 30 items.')
          return prev
        }

        const newItemList = [...prev.itemList, itemMaps[tokenId]]
        return {
          itemList: [...prev.itemList, itemMaps[tokenId]],
          itemSet: new Set(newItemList.map((item) => item.tokenId)),
        }
      })
    }
  }

  const sendTx = async (
    signer: ethers.Signer,
    txParams: ethers.utils.Deferrable<ethers.providers.TransactionRequest>,
    items: ItemSellOrderParams[],
  ) => {
    if (chain?.id !== 10) {
      toast.error('please switch to the Optimism network')
      if (switchNetwork) {
        switchNetwork(10)
      } else {
        const errMessage =
          'failed to switch the network, switchNetwork is invalid'
        captureMessage('error', errMessage)
        console.error(errMessage)
      }
      return
    }

    items.forEach((item) => {
      setBuyingItems((prev) => ({ ...prev, [item.tokenId]: true }))
    })

    let tx: ethers.providers.TransactionResponse
    try {
      tx = await signer.sendTransaction(txParams)
    } catch (error: any) {
      items.forEach((item) => {
        setBuyingItems((prev) => ({ ...prev, [item.tokenId]: false }))
      })
      setIsCheckingOut(false)

      const parsedError = humanifyWeb3Error(error)

      toast.error(`failure: ${parsedError}`)
      trackEvent(AnalyticsEvent.CheckOutTransactionCannotSent, {
        message: parsedError,
        items: selectedItems.itemList.length,
      })

      if (parsedError === errNotEnoughEth) {
        captureMessage('info', parsedError, {
          web3: {
            error: parsedError,
            data: {
              collection: collectionName,
              items: selectedItems.itemList.length,
              ...txParams,
            },
          },
        })
      }
      return
    }

    let itemString = ''

    let message = ''
    if (items.length === 1) {
      itemString = items[0].name
      message = `Buy ${itemString}`
    } else if (items.length > 1) {
      itemString = `${items.length} ${items[0].collectionName} tokens`
      message = `Sweep ${itemString}`
    } else {
      console.error('invalid items:', items)
    }

    setCheckoutTxHash(tx.hash)
    setIsTxSent((prev) => ({ ...prev, [tx.hash]: true }))

    addRecentTransaction({
      hash: tx.hash,
      description: message,
    })
    trackEvent(AnalyticsEvent.CheckOutTransactionSent)

    const receipt = await tx.wait()

    setIsTxConfirmed((prev) => ({ ...prev, [tx.hash]: true }))
    setTxReceipts((prev) => ({ ...prev, [tx.hash]: receipt }))

    if (receipt.status === 1) {
      items.forEach((item) => {
        // set all as sold regardless of refunded
        // bc the refunded ones is likely to be sold already too
        setSoldItems((prev) => ({ ...prev, [item.tokenId]: true }))
      })

      handleAddOptimisticActivityUpdate(receipt)

      // TODO: track fulfilled amount
      trackEvent(AnalyticsEvent.CheckOutTransactionSuccessful, {
        items: selectedItems.itemList.length,
        buyer: shortAddress(tx.from),
        connectorId: connector?.id,
        connectorName: connector?.name,
        ...collectionDetail,
      })
    } else {
      // TODO: make it clickable to explorer
      captureMessage('info', 'user failed transaction', {
        transaction: { hash: tx.hash },
      })
      trackEvent(AnalyticsEvent.CheckOutTransactionReverted)
    }

    // update the result
    items.forEach((item) => {
      setBuyingItems((prev) => ({ ...prev, [item.tokenId]: false }))
    })
  }

  const handleReviewOrder = async () => {
    if (isError || isLoading || !signer) {
      toast.error('please connect the wallet')
      handleScrollAndGuideToConnectWallet()
      return
    }

    setCartValidation({ result: null, validating: true })
    setIsReviewingOrder(true)

    try {
      const txParams = computeTxData(
        selectedItems.itemList.map((item) => item.txBuyData),
      )

      if (txParams) {
        if (!signer.provider) {
          throw new Error('Missing provider')
        }

        // TODO: no need to use txParams, just the selected items are enough
        const results = await validateOrdersSimple(
          selectedItems.itemList,
          signer.provider,
        )

        // TODO: handle undefined
        results?.forEach((result) => {
          if (!result.success) {
            // TODO: rename to invalid items
            setSoldItems((prev) => ({ ...prev, [result.tokenId]: true }))
          }
        })

        setCartValidation({
          result: results,
          validating: false,
        })
      }
    } catch (error) {
      console.error('failed to validate orders', error)
      setCartValidation({ result: null, validating: false })
    }

    return true
  }

  const handleCheckout = async () => {
    if (isError || isLoading || !signer) {
      toast.error('please connect the wallet')
      handleScrollAndGuideToConnectWallet()
      return
    }

    if (cartValidation.validating) {
      console.error('still validating cart')
      return
    }

    const failedValidationMap: Record<TokenId, boolean> = {}
    cartValidation.result?.forEach((result) => {
      if (!result.success) {
        failedValidationMap[result.tokenId] = true
      }
    })

    // remove unavailable items
    selectedItems.itemList.forEach((item) => {
      if (failedValidationMap[item.tokenId]) {
        handleSelectItem(item.tokenId, true)
      }
    })

    // need to filter out manually, since the cart item removal might not finished yet
    // note that the validation might be empty if failed to validate, it should not affect the checkout process
    const selectedValidItems = selectedItems.itemList.filter((item) => {
      if (failedValidationMap[item.tokenId]) {
        return false
      }
      return true
    })

    const txParams = computeTxData(
      selectedValidItems.map((item) => item.txBuyData),
    )

    const MAX_GAS_LIMIT = 15000000
    if (txParams) {
      setIsReviewingOrder(false)
      setIsCheckingOut(true)

      let estGasLimit: number | null = null

      console.log('Using transaction params', txParams)

      try {
        const gasLimit = await signer.estimateGas(txParams)
        estGasLimit = gasLimit.toNumber()
        estGasLimit = Math.round(estGasLimit * 2)
        estGasLimit = Math.min(estGasLimit, MAX_GAS_LIMIT)

        txParams.gasLimit = estGasLimit
        console.log('estimated gas limit:', estGasLimit)
      } catch (error) {
        console.error('failed to estimate gas limit:', error)
      }

      try {
        const signedTC = getSignedTCVersion()
        if (!signedTC) {
          const address = await signer.getAddress()
          trackEvent(AnalyticsEvent.TermsSignatureRequested)
          const signature = await signer.signMessage(getTCMessage(address))

          if (signature) {
            saveSignedTC(signature, address)
            trackEvent(AnalyticsEvent.TermsSignatureSigned)
          } else {
            toast.error('invalid signature')
            return
          }
        }
      } catch (error) {
        toast.error('signature request rejected, please sign to proceed')
        trackEvent(AnalyticsEvent.TermsSignatureRejected)
        return
      }

      const tx = await sendTx(signer, txParams, selectedValidItems)
    } else {
      toast.error('no items in the cart')
    }
  }

  // Sorry this starts to be messy. We should move the logic out of here.
  // Grouping into the new hook could be a good idea.
  const handleAddOptimisticActivityUpdate = (
    txReceipt: ethers.providers.TransactionReceipt | undefined | null,
  ) => {
    if (!txReceipt || txReceipt.status === 0) {
      return
    }

    const result = parseLogs(txReceipt.logs)

    // add update only the successful buy (has log)
    result?.operatorLogs?.forEach(async (log) => {
      const item = itemMaps[log.tokenId.toString()]

      if (!item) {
        return
      }

      activitiesOptimisticUpdate({
        chainId: item.chainId,
        txHash: txReceipt.transactionHash,
        tokenId: log.tokenId.toString(),
        tokenName: item.name,
        tokenImage: item.image,
        // assuming we always buy with ETH for now
        paymentAmountEth: log.paymentAmount.toString(),
        paymentAmount: log.paymentAmount.toString(),
        paymentToken: {
          address: '0x0000000000000000000000000000000000000000',
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        // TODO: may consider changing to the real block time
        //  however, the current one is acceptable for now.
        matchedAt: new Date().toISOString(),
        buyer: {
          address: BLUESWEEP_OPERATOR_CONTRACT,
          username: null,
          reverseEns: 'bluesweep.eth',
        },
        seller: {
          address: item.seller,
          username: null,
          reverseEns: null,
        },
        type: 'TAKER_BUY',
        marketplace: item.marketplace,
        orderId: item.orderId,
      })
    })
  }

  const handleReloadCollection = async () => {
    await handleReload()
  }

  const handleClearCheckoutCart = () => {
    setSelectedItems(DEFAULT_SELECTED_ITEMS)
    setIsSweepMode(false)
    setIsCheckingOut(false)
    setIsReviewingOrder(false)
    setCheckoutTxHash('')
  }

  return (
    <>
      <div className="mx-4 my-4 md:my-0 lg:ml-4 xl:ml-8 xl:mr-4 h-full">
        {/* prefetch the usd price */}
        <div className="hidden">
          <EthPriceInUsd wei={1000000000} />
        </div>

        <div className="lg:mr-4">
          <HeaderSection connectButtonAnimatedStyles={styles} />
        </div>

        {selectedItems.itemList.length > 0 && (
          <CheckOutSection
            items={selectedItems.itemList}
            isCheckingOut={isCheckingOut}
            isTxConfirmed={isTxConfirmed[checkoutTxHash]}
            percentageGasSaved={sweepGasInfo?.percentageSaved}
            onClickReviewOrder={() => {
              handleReviewOrder()
              trackEvent(AnalyticsEvent.CheckOutReviewOrderClicked)
            }}
            onClickCheckOut={() => {
              handleCheckout()
              trackEvent(AnalyticsEvent.CheckOutButtonClicked, {
                selectedItems: selectedItems.itemList.length,
              })
            }}
            onRemoveItem={(tokenId) => {
              handleSelectItem(tokenId)
              trackEvent(AnalyticsEvent.CheckOutRemoveItemClicked)
            }}
            onClearCart={() => {
              handleClearCheckoutCart()
              trackEvent(AnalyticsEvent.CheckOutClearButtonClicked)
            }}
          />
        )}

        <div className="relative z-100">
          <div>
            <CheckOutConfirmationSection
              isOpen={isCheckingOut || isReviewingOrder}
              onCloseAfterDone={() => {
                handleClearCheckoutCart()
                trackEvent(AnalyticsEvent.CheckOutConfirmationDoneClicked)
              }}
              onClearCart={() => {
                handleClearCheckoutCart()
                trackEvent(AnalyticsEvent.CheckOutConfirmationCartClearClicked)
              }}
              onCheckOutConfirmed={() => {
                handleCheckout()
                trackEvent(
                  AnalyticsEvent.CheckOutConfirmationCheckOutButtonClicked,
                  { selectedItems: selectedItems.itemList.length },
                )
              }}
              onCheckOutCanceled={() => {
                setIsReviewingOrder(false)
                trackEvent(
                  AnalyticsEvent.CheckOutConfirmationCancelButtonClicked,
                )
              }}
              onRemoveItem={(tokenId) => {
                // close the modal if removing the last item
                if (selectedItems.itemList.length === 1) {
                  setIsReviewingOrder(false)
                }
                handleSelectItem(tokenId, true)
                trackEvent(AnalyticsEvent.CheckOutConfirmationRemoveItemClicked)
              }}
              collectionInfo={collectionDetail}
              items={selectedItems.itemList}
              soldItems={soldItems}
              txHash={checkoutTxHash}
              isTxSent={isTxSent[checkoutTxHash]}
              isTxConfirmed={isTxConfirmed[checkoutTxHash]}
              isReviewingOrder={isReviewingOrder}
              isValidatingOrder={cartValidation.validating}
              txReceipt={txReceipts[checkoutTxHash]}
              sweepGasInfo={sweepGasInfo}
            />
          </div>
        </div>

        <div className="flex space-x-2 xl:space-x-4">
          {/* Main Panel */}
          <div className="w-full xl:w-4/5">
            <div className="md:mt-6 flex justify-between items-start">
              <CollectionHeaderSection collectionDetail={collectionDetail} />

              {/* Sweep mode for desktop, hidden on mobile */}
              <div className="hidden md:block">
                <div className="flex mt-1 w-full justify-end">
                  <SweepModeButton
                    isOn={isSweepMode}
                    onClick={() => {
                      setIsSweepMode(!isSweepMode)
                      trackEvent(AnalyticsEvent.SweepModeButtonClicked)
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Tab Select for mobile */}
            <div className="block md:hidden mt-8 relative">
              <MobileTabMenu
                selectedTab={selectedTab}
                onSelectTab={(tab) => setSelectedTab(tab)}
              />
            </div>

            {selectedTab === 'items' && (
              <>
                {/* SweepMode Button for mobile*/}
                <div className="block md:hidden mt-4 w-full">
                  <div className="flex justify-end">
                    <SweepModeButton
                      isOn={isSweepMode}
                      onClick={() => {
                        setIsSweepMode(!isSweepMode)
                        trackEvent(AnalyticsEvent.SweepModeButtonClicked)
                      }}
                      className="w-full text-lg"
                    />
                  </div>
                </div>

                <div className="my-2">
                  <Reload
                    handleLoadResources={handleReloadCollection}
                    isLoading={loading}
                  />
                  <Transition
                    show={isSweepMode}
                    enter="transform transition ease-in-out duration-100"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transform transition ease-in-out duration-100"
                    leaveFrom="opacity-100 rotate-0 scale-100 "
                    leaveTo="opacity-0 scale-95 "
                  >
                    <SweepModeControlSection
                      sortedItems={collectionSales?.filter(
                        (item) => !soldItems[item.tokenId],
                      )}
                      collectionName={collectionDetail?.name}
                      onSelectedItemChange={(tokenIds) =>
                        setSelectedItems({
                          itemList: tokenIds.map(
                            (tokenId) => itemMaps[tokenId],
                          ),
                          itemSet: new Set(tokenIds),
                        })
                      }
                      onStopSweepMode={() => setIsSweepMode(false)}
                    />
                  </Transition>
                </div>
                <div className="my-4 md:my-8 pb-32">
                  <CollectionItemsSection
                    error={error}
                    selectedItemSet={selectedItems.itemSet}
                    buyingItems={buyingItems}
                    soldItems={soldItems}
                    isLoading={loading}
                    collectionSales={collectionSales || []}
                    onSelectItem={(tokenId, index) => {
                      handleSelectItem(tokenId)
                      trackEvent(AnalyticsEvent.ItemClicked, { index })
                    }}
                    collectionDetail={collectionDetail}
                  />
                </div>
              </>
            )}

            {/* For mobile */}
            {selectedTab === 'activities' && (
              <LatestActivitiesSection
                latestSaleActivities={activities}
                collectionDetail={collectionDetail}
                error={activityError}
                isLoading={activitiesLoading}
              />
            )}
          </div>

          {/* Right Activitiy Panel */}
          <div className="hidden md:block xl:w-1/5 h-screen sticky -top-2">
            <div className="mt-6 h-full overflow-y-scroll min-w-max no-scrollbar">
              {/* Add width 234px to sidebar content width while loading */}
              <div className="font-semibold text-slate-600 ml-4 w-[234px]">
                Live Market Activity
              </div>

              <LatestActivitiesSection
                latestSaleActivities={activities}
                collectionDetail={collectionDetail}
                error={activityError}
                isLoading={activitiesLoading}
              />
            </div>
          </div>
        </div>

        <FooterSection />
      </div>
    </>
  )
}

export default Collection
