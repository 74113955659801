import ERC721Item from '@/components/ERC721Item'
import { CollectionDetailApi, ItemSellOrderParams, TokenId } from '@/types'
import Link from 'next/link'
import { useEffect } from 'react'

type CollectionItemsSectionProps = {
  error: any
  collectionDetail: CollectionDetailApi | null
  collectionSales: ItemSellOrderParams[]
  // index is sent in case of event tracking
  onSelectItem: (tokenId: string, index: number) => void
  selectedItemSet: Set<TokenId>
  buyingItems: Record<string, boolean>
  soldItems: Record<string, boolean>
  isLoading: boolean
}
const CollectionItemsSection: React.FC<CollectionItemsSectionProps> = ({
  error,
  collectionDetail,
  collectionSales,
  selectedItemSet,
  onSelectItem,
  buyingItems,
  soldItems,
  isLoading,
}) => {
  useEffect(() => {}, [buyingItems])

  if (isLoading) {
    return (
      <div className="w-full h-24 flex justify-center items-center text-slate-500 text-2xl">
        Loading...
      </div>
    )
  }

  if (error) {
    return (
      <div className="w-full h-24 flex flex-col justify-center items-center ">
        <div className="text-slate-500 text-2xl">404 Collection Not Found</div>
        <div className="text-slate-400 text-sm mt-2">
          {' '}
          <Link href="/">Go back to home</Link>
        </div>
      </div>
    )
  }

  if (!collectionSales || collectionSales.length === 0) {
    return (
      <div className="w-full h-24 flex flex-col justify-center items-center ">
        <div className="text-slate-500 text-2xl">No Item Sell Listed</div>
        <div className="text-slate-400 text-sm mt-2">
          {' '}
          Please come back again later
        </div>
      </div>
    )
  }

  return (
    <div
      className="grid grid-cols-2 gap-x-3 gap-y-4
    md:grid-cols-3 md:gap-6
    lg:grid-cols-4 lg:gap-4
    xl:grid-cols-4 xl:gap-6
    2xl:grid-cols-5"
    >
      {collectionSales.map((item, index) => {
        return (
          <ERC721Item
            key={`${item.marketplace}-${item.tokenId}`}
            collectionDetail={collectionDetail}
            item={item}
            onClick={() => onSelectItem(item.tokenId, index)}
            isSelected={selectedItemSet.has(item.tokenId)}
            isBuying={buyingItems[item.tokenId]}
            isSold={soldItems[item.tokenId]}
          />
        )
      })}
    </div>
  )
}

export default CollectionItemsSection
