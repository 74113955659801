import { CHAINS } from "@/types"

export function formatTxHash(hash: string) {
  if (!hash) {
    return 'N/A'
  }

  return `${hash.slice(0, 5)}...${hash.slice(-4)}`
}

// TODO: add more chains
export function getEtherscanUrl(hash: string, chainId: number) {
  if (chainId !== 10) {
    return ''
  }
  return `https://optimistic.etherscan.io/tx/${hash}`
}

export function getEtherscanUrlByChain(hash: string, chain: CHAINS) {
  if (chain !== CHAINS.OPTIMISM) {
    return ''
  }
  return `https://optimistic.etherscan.io/tx/${hash}`
}