import { captureMessage } from '../sentry'
import { errorCodes } from './metamask-errors'

type EthersError = {
  code: number | string
  message?: string
  data?: {
    code: number
    message: string
  }
  error?: {
    code: number
    message: string
  }
  stack?: string

  method?: string
  reason?: string
  transaction?: any
}

export const errDefaultUnknown = 'transaction failed to send'
export const errTxRejected = 'transaction rejected'
export const errNotEnoughEth = 'not enough ETH balance'
export const errGasExceeded = 'not enough ETH balance or item already sold'
export const errSoldOrNotAvail = 'items no longer available'

export const humanifyWeb3Error = (web3Error: EthersError): string => {
  let message = web3Error.message
  let data = web3Error.data || web3Error.error

  console.log('web3Error.message', typeof message, message)
  console.log('web3Error.data', typeof data, data)
  console.log('web3Error', JSON.stringify(web3Error))

  if (web3Error.code === errorCodes.provider.userRejectedRequest) {
    return errTxRejected
  }

  if (typeof data?.message === 'string') {
    if (data?.message.includes('gas required exceeds allowance')) {
      return errGasExceeded
    }

    if (data?.message.includes('insufficient balance for transfer')) {
      return errNotEnoughEth
    }

    if (data.code === errorCodes.provider.userRejectedRequest) {
      return errTxRejected
    }
  }

  if (message?.includes('gas required exceeds allowance')) {
    return errGasExceeded
  }

  if (message?.includes('insufficient funds for intrinsic transaction cost')) {
    return errNotEnoughEth
  }

  if (message?.includes('denied transaction signature')) {
    return errTxRejected
  }

  if (message?.includes('User rejected the transaction')) {
    return errTxRejected
  }

  if (message?.includes('User denied transaction signature')) {
    return errTxRejected
  }

  if (message?.includes('User denied message signature')) {
    return errTxRejected
  }

  if (message?.includes('Cancelled by User')) {
    return errTxRejected
  }


  // v6 error when token already sold
  if (message?.includes('execution error')) {
    captureMessage('info', 'web3: execution error ', {
      web3: { error: message },
    })
    return errSoldOrNotAvail
  }

  // v5 error when token already sold
  if (message?.includes('Signature is not valid for SellOrder')) {
    captureMessage('info', 'web3: Signature is not valid for SellOrder ', {
      web3: { error: message },
    })
    return errSoldOrNotAvail
  }

  captureMessage('error', 'unknown web3 error', {
    web3: { error: message || 'empty error message', data: web3Error },
  })

  return errDefaultUnknown
}
