import { useState, useEffect } from "react"
import { useSpring } from "react-spring"

// ref: https://www.joshwcomeau.com/snippets/react-hooks/use-boop/
const useBounce = ({ scale = 1.1, duration = 150, config = { tension: 360, friction: 16 } }) => {
  const [isBouncing, setIsBouncing] = useState(false)

  const styles = useSpring({
    display: 'inline-block',
    transform: isBouncing ? `scale(${scale})` : `scale(1)`,
    config: config,
  })

  useEffect(() => {
    // We only want to act when we're going from
    // not-booped to booped.
    if (!isBouncing) {
      return
    }

    const timeoutId = window.setTimeout(() => {
      setIsBouncing(false)
    }, duration)
    // Just in case our component happens to
    // unmount while we're booped, cancel
    // the timeout to avoid a memory leak.
    return () => {
      window.clearTimeout(timeoutId)
    }
    // Trigger this effect whenever `isBooped`
    // changes. We also listen for `timing` changes,
    // in case the length of the boop delay is
    // variable.
  }, [isBouncing, duration])


  const trigger = () => {
    setIsBouncing(true)
  }

  return { styles, trigger }
}

export default useBounce