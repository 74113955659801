import { TxBuyData } from '@/types'
import { BigNumber, ethers } from 'ethers'

export const computeTxData = (
  txBuyDataList: TxBuyData[],
): ethers.utils.Deferrable<ethers.providers.TransactionRequest> | null => {
  if (txBuyDataList.length === 1) {
    const buyData = txBuyDataList[0].buy
    return {
      to: buyData.to,
      value: buyData.value,
      data: buyData.data,
    }
  } else if (txBuyDataList.length > 1) {
    let valueBN = BigNumber.from('0')
    let data = ethers.utils.solidityPack(
      ['bytes4', 'uint8'],
      [txBuyDataList[0].sweep.prefix, txBuyDataList.length],
    )

    txBuyDataList.forEach((txBuyData) => {
      valueBN = valueBN.add(txBuyData.buy.value)
      data = data.concat(txBuyData.sweep.payload.slice(2))
    })

    return {
      to: txBuyDataList[0].buy.to,
      value: valueBN.toString(),
      data: data,
    }
  } else {
    console.error('no items in the checkout cart')
    return null
  }
}
