import {
  CollectionParams,
  CollectionStatsParams,
  ItemSellOrderParams,
} from '@/types'
import useSWR from 'swr'
import { API_HOST } from '../constants'
import { fetcher } from '../libs/fetcher'

export const useCollectionTokens = (
  collectionName: string | string[] | undefined,
) => {
  let shouldFetch = true

  if (typeof collectionName !== 'string') {
    shouldFetch = false
  }

  // const url = `${API_HOST}/collectionsByName/${collectionName}`
  const url = `${API_HOST}/v2/itemSellOrdersBySlug/${collectionName}`
  const { data, error, mutate } = useSWR(shouldFetch ? url : '', fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    // refresh list after changing page
    revalidateOnMount: true,
  })

  const handleReload = async () => {
    let result = await mutate()
    return result
  }

  return {
    collectionSales: data?.data as ItemSellOrderParams[],
    // collectionStats: data?.data?.collectionStats as CollectionStatsParams,
    // collectionInfo: data?.data?.collection as CollectionParams,
    collectionStats: null as CollectionStatsParams | null,
    collectionInfo: null as CollectionParams | null,
    error: error,
    loading: !data && !error,
    handleReload,
  }
}
