import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import OptimizedImage from './OptimizedImage'
import Icon from './Icon'

type CollectionExternalLinksProps = {
  website?: string | null
  twitter?: string | null
  discord?: string | null
  apetimismPad?: string | null
  quixotic?: string
  etherscan?: string
}
const CollectionExternalLinks: React.FC<CollectionExternalLinksProps> = ({
  website,
  twitter,
  discord,
  quixotic,
  apetimismPad,
  etherscan,
}) => {
  const { trackEvent } = useAnalytics()

  const baseClass = 'cursor-pointer hover:opacity-75 hover:scale-125'

  return (
    <div className="flex space-x-4 items-center opacity-75">
      {website && (
        <a
          href={website}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            trackEvent(AnalyticsEvent.ExternalLinkedClicked, {
              link: 'website',
            })
          }
        >
          <div className={`${baseClass} text-slate-500`}>
            <Icon name="website" />
          </div>
        </a>
      )}

      {twitter && (
        <a
          href={twitter}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            trackEvent(AnalyticsEvent.ExternalLinkedClicked, {
              link: 'twitter',
            })
          }
        >
          <div className={`${baseClass} text-slate-500`}>
            <Icon name="twitter" />
          </div>
        </a>
      )}

      {discord && (
        <a
          href={discord}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            trackEvent(AnalyticsEvent.ExternalLinkedClicked, {
              link: 'discord',
            })
          }
        >
          <div className={`${baseClass} text-slate-500`}>
            <Icon name="discord" />
          </div>
        </a>
      )}

      {apetimismPad && (
        <a
          href={apetimismPad}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            trackEvent(AnalyticsEvent.ExternalLinkedClicked, {
              link: 'apetimismPad',
            })
          }
        >
          <div className={baseClass}>
            <OptimizedImage
              src="/icon-apetimism.png"
              alt="apetimism launchpad"
              width="15"
              height="15"
            />
          </div>
        </a>
      )}

      {etherscan && (
        <a
          href={etherscan}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            trackEvent(AnalyticsEvent.ExternalLinkedClicked, {
              link: 'etherscan',
            })
          }
        >
          <div className={baseClass}>
            <OptimizedImage
              src="/icon-etherscan.svg"
              alt="quixotic"
              width="14"
              height="14"
            />
          </div>
        </a>
      )}
    </div>
  )
}

export default CollectionExternalLinks
