import { bnSum } from '@/libs/bigNumberMath'
import { ItemSellOrderParams } from '@/types'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { ChangeEvent, useState } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import NumericInput from '@/components/NumericInput'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import { MAX_SWEEP_ITEMS } from '@/constants'
import { isNumber, isLeadingZero } from '@/libs/utils'

// the current constrain is from UI design
// the actual contract maximum items can be more larger
const MIN_ITEMS = 0
const MAX_ITEMS = MAX_SWEEP_ITEMS

const sliderHandleStyle = {
  borderColor: '#9333ea',
  borderWidth: 4,
  height: 28,
  width: 28,
  marginLeft: 6,
  marginTop: -9,
  backgroundColor: 'white',
  opacity: 1,
}
const sliderTrackStyle = {
  height: 8,
  backgroundImage:
    'radial-gradient( circle farthest-corner at 92.3% 71.5%,  #9333ea 0%, #3b82f6 90% )',
}
const sliderRailStyle = {
  height: 10,
  backgroundColor: '#eaeaea',
}

type Props = {
  collectionName: string | undefined
  // assuming sorted from lowest to highest price
  sortedItems: ItemSellOrderParams[]
  onSelectedItemChange: (selectedItems: string[]) => void
  onStopSweepMode: () => void
}
const SweepModeControlSection: React.FC<Props> = ({
  collectionName,
  sortedItems,
  onSelectedItemChange,
  onStopSweepMode,
}) => {
  const { trackEvent } = useAnalytics()

  const [totalItems, setTotalItems] = useState(0)
  const [totalPayment, setTotalPayment] = useState<BigNumber>(BigNumber.from(0))

  const handleItemsChange = (totalItems: number) => {
    if (isNumber(totalItems)) {
      setTotalItems(0)
    }

    // parse the number to make it within the valid integer range
    let total = Math.max(Math.min(MAX_ITEMS, totalItems), MIN_ITEMS)
    total = Math.floor(total)

    setTotalItems(total)

    const selectedItems = sortedItems.slice(0, total)

    onSelectedItemChange(selectedItems.map((item) => item.tokenId))

    const totalPayment = bnSum(selectedItems.map((item) => item.paymentAmount))

    setTotalPayment(totalPayment)
  }

  return (
    <div className="p-8 rounded-lg bg-slate-100 relative border-primary border-solid border-2">
      <div
        onClick={() => {
          onStopSweepMode()
          trackEvent(AnalyticsEvent.SweepModePanelClose)
        }}
        className="absolute -right-2 -top-2 text-primary hover:text-rose-500 bg-white cursor-pointer rounded-full"
      >
        <AiOutlineCloseCircle size="30" />
      </div>
      <div className="flex items-center">
        <div className="text-2xl text-slate-700 font-semibold">
          Sweeping <span className="hidden md:block">{collectionName}</span>
        </div>

        <div className="flex items-center ml-8 xl:ml-16 text-slate-600">
          <div>Total items:</div>
          <NumericInput
            value={totalItems}
            onChange={handleItemsChange}
            onClick={() =>
              trackEvent(AnalyticsEvent.SweepModePanelTotalItemsInputClick)
            }
            className="
            w-16 md:w-24 lg:w-36
            py-2 px-4 ml-4 text-xl appearance-none"
          />
        </div>

        <div className="hidden lg:block mx-8 text-3xl font-thin">=</div>

        <div className="hidden lg:block">
          <div className="flex items-center text-slate-600">
            <div>Total payment:</div>
            <input
              type="string"
              value={`${formatEther(totalPayment)} ETH`}
              disabled
              className="py-2 px-4 ml-4 text-xl w-48 disabled:bg-white/30"
            />
          </div>
        </div>
      </div>

      <div
        className="mt-8"
        onClick={() => trackEvent(AnalyticsEvent.SweepModePanelSliderClick)}
      >
        <Slider
          min={MIN_ITEMS}
          max={MAX_ITEMS}
          value={totalItems}
          defaultValue={MIN_ITEMS}
          onChange={(n) => {
            if (typeof n === 'number') {
              handleItemsChange(n)
            }
          }}
          railStyle={sliderRailStyle}
          handleStyle={sliderHandleStyle}
          trackStyle={sliderTrackStyle}
        />
      </div>
    </div>
  )
}

export default SweepModeControlSection
