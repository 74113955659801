import { API_HOST } from '@/constants'
import { fetcher } from '@/libs/fetcher'
import { SaleActivityParams } from '@/types'
import { DateTime } from 'luxon'
import { useState } from 'react'
import useSWR from 'swr'

const REFRESH_INTERVAL_MS = 2400

export const useCollectionActivities = (
  collectionSlug: string | string[] | undefined,
) => {
  const [optimisticActivities, setOptimisticActivities] = useState<
    SaleActivityParams[]
  >([])

  const shouldFetch = collectionSlug && typeof collectionSlug === 'string'

  const url = `${API_HOST}/v2/saleActivitiesBySlug/${collectionSlug}`
  const { data, error } = useSWR(shouldFetch ? url : '', fetcher, {
    refreshInterval: REFRESH_INTERVAL_MS,
  })

  // use this way instead of mutate bc the real API takes 15 seconds to update
  // so when we refresh, the optimistic activities will be gone
  // we need to persist the optimisite activities, at least until the real update comes in
  const optimisticUpdate = (newSaleActivity: SaleActivityParams) => {
    setOptimisticActivities((prev) => {
      const newActivities = [newSaleActivity, ...prev]
      return newActivities
    })
  }

  return {
    data: mergeActivities(optimisticActivities, data?.data || []),
    error,
    isLoading: !error && !data,
    optimisticUpdate,
  }
}

// merged distint activities (dup = same txHash and token) and sort
function mergeActivities(
  optimisticActivities: SaleActivityParams[],
  originalActivities: SaleActivityParams[],
) {
  // merge activities with optimistic activities
  const allActivities = [...optimisticActivities, ...originalActivities]

  // filter duplicate txHash & tokenId of activities
  const uniqueActivities = allActivities.reduce(
    (activities: SaleActivityParams[], curr) => {
      if (
        !activities.find(
          ({ txHash, tokenId }) =>
            txHash === curr.txHash && tokenId === curr.tokenId,
        )
      ) {
        activities.push(curr)
      }
      return activities
    },
    [],
  )
  // sort activities by matchedAt and tokenId
  // since one tx can have multiple tokenIds
  const sortedActivities = uniqueActivities.sort(
    (a, b) =>
      DateTime.fromISO(b.matchedAt).toMillis() -
        DateTime.fromISO(a.matchedAt).toMillis() ||
      b.tokenId.localeCompare(a.tokenId),
  )

  return sortedActivities
}
