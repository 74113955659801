import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import { useRouter } from 'next/router'
import { useAccount } from 'wagmi'
import {
  META_DESC,
  META_IMAGE,
  META_TITLE,
  META_TWITTER_HANDLE,
} from '@/constants'
import { useEffect, useState } from 'react'
import { animated, SpringValue } from 'react-spring'
import Searchinput from '@/components/Searchinput'
import { FiSearch } from 'react-icons/fi'
import SearchResult from '@/components/SearchResult'

type Props = {
  connectButtonAnimatedStyles?: {
    display: SpringValue<string>
    transform: SpringValue<string>
  }
}

const HeaderSection: React.FC<Props> = ({ connectButtonAnimatedStyles }) => {
  const { trackEvent } = useAnalytics()
  const router = useRouter()
  const { isConnected } = useAccount()
  const [isSearchBar, setIsSearchBar] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [isDisplaySearch, setDisplaySearch] = useState<boolean>(false)
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null,
  )

  const handleSearch = (value: string) => {
    setSearch(value)
    setDisplaySearch(false)
    if (searchTimeOut) {
      clearTimeout(searchTimeOut)
    }
    if (search.length % 3 === 0) {
      setDisplaySearch(true)
      trackEvent(AnalyticsEvent.SearchInput, {
        keyword: search,
      })
    }
    setSearchTimeOut(
      setTimeout(() => {
        setDisplaySearch(true)
        trackEvent(AnalyticsEvent.SearchInput, {
          keyword: search,
        })
      }, 1000),
    )
  }

  const handleSubmitSearch = () => {
    router.push(`/search?s=${search}`)
  }

  let fullSearchBarStyle = ''

  useEffect(() => {
    fullSearchBarStyle =
      process.env.NEXT_PUBLIC_ENABLE_SEARCH_FEATURE === 'true' && isSearchBar
        ? 'hidden'
        : ''
  }, [isSearchBar])

  return (
    <div>
      <Head>
        <title>{META_TITLE}</title>
        <meta name="description" content={META_DESC} />
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Meta Metadata */}
        <meta name="og:title" content={META_TITLE} />
        <meta name="og:description" content={META_DESC} />
        <meta name="og:image" content={META_IMAGE} />

        {/* Twitter Metadata */}
        <meta property="twitter:title" content={META_TITLE} />
        <meta property="twitter:image" content={META_IMAGE} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={META_TWITTER_HANDLE} />
      </Head>

      <div className="flex justify-between items-center my-6">
        <Link href="/">
          <div
            className={`${
              process.env.NEXT_PUBLIC_ENABLE_SEARCH_FEATURE === 'true' &&
              isSearchBar === true
                ? 'hidden'
                : ''
            } flex items-center hover:scale-[1.025] active:scale-100 transition-transform cursor-pointer md:flex`}
            onClick={() => {
              trackEvent(AnalyticsEvent.LogoClicked, {
                fromPathName: router.pathname,
                fromAsPath: router.asPath,
              })
            }}
          >
            {/* Desktop logo */}
            <div className="relative hidden md:block w-[169px] h-[32px]">
              <Image
                src="/bluesweep-logo.png"
                alt="bluesweep logo"
                objectFit="cover"
                layout="fill"
              />
            </div>
            <div className="hidden md:block bg-blue-300 text-white px-2 py-[2px] rounded text-xs ml-2 font-medium">
              BETA
            </div>

            {/* Mobile logo */}
            {/* 908 x 200 */}
            <div className="relative block md:hidden w-[144px] h-[32px]">
              <Image
                className="block md:hidden"
                src="/bluesweep-logo-beta.png"
                alt="bluesweep logo"
                layout="fill"
                objectFit="cover"
              />
            </div>

            {process.env.NEXT_PUBLIC_ENABLE_SCREEN_SIZE === 'true' && (
              <div className="ml-4">
                <div className="hidden md:block lg:hidden">MD</div>
                <div className="hidden lg:block xl:hidden">LG</div>
                <div className="hidden xl:block 2xl:hidden">XL</div>
                <div className="hidden 2xl:block 3xl:hidden">2XL</div>
                <div className="hidden 3xl:block">3XL+</div>
              </div>
            )}
          </div>
        </Link>
        {process.env.NEXT_PUBLIC_ENABLE_SEARCH_FEATURE === 'true' && (
          <div className="flex flex-col w-full my-1 md:mx-4 relative items-center">
            <FiSearch
              onClick={() => {
                setIsSearchBar(true)
              }}
              size="24"
              className={`${
                process.env.NEXT_PUBLIC_ENABLE_SEARCH_FEATURE === 'true' &&
                isSearchBar === true
                  ? 'hidden'
                  : ''
              } block md:hidden cursor-pointer`}
            />
            {/* Desktop View */}
            <Searchinput
              placeholder="Search here"
              value={search}
              onChange={handleSearch}
              onClick={() => {
                setDisplaySearch(true)
                trackEvent(AnalyticsEvent.SearchClicked)
              }}
              className="border border-slate-400 border-1 w-full px-4 py-1 rounded-md hidden md:block z-[21]"
            />
            {/* Mobile View */}
            <Searchinput
              placeholder="Search here"
              value={search}
              onChange={handleSearch}
              onClick={() => {
                setDisplaySearch(true)
                trackEvent(AnalyticsEvent.SearchClicked)
              }}
              className={`${
                process.env.NEXT_PUBLIC_ENABLE_SEARCH_FEATURE === 'true' &&
                isSearchBar === true
                  ? 'block'
                  : 'hidden'
              } border border-slate-400 border-1 w-full px-2 py-1 rounded-md md:hidden z-[21]`}
            />
            {isDisplaySearch && (
              <SearchResult
                close={() => {
                  setDisplaySearch(false)
                }}
                query={search}
              />
            )}
          </div>
        )}
        <div
          className={`${
            process.env.NEXT_PUBLIC_ENABLE_SEARCH_FEATURE === 'true' &&
            isSearchBar === true
              ? 'hidden'
              : ''
          } shrink-0 md:block`}
          onClick={() => {
            if (isConnected) {
              trackEvent(AnalyticsEvent.AccountButtonClicked)
            } else {
              // want to see which page user is connecting the wallet
              trackEvent(AnalyticsEvent.ConnectButtonClicked, {
                fromPathName: router.pathname,
                fromAsPath: router.asPath,
              })
            }
          }}
        >
          <animated.div style={connectButtonAnimatedStyles}>
            <ConnectButton accountStatus="address" />
          </animated.div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSection
